.ProgressBar {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.div-align-progressbar {
  width: 100%;
  border-radius: 0.375rem;
  background-color: #F6F7FC;
  height: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0 auto;
}

.div-progress-bar {
  background-size: 1rem 1rem;
  height: 100%;
}

.red-progress-bar {
  background-color: rgba(239, 69, 69, 0.59);
  background-image: linear-gradient(45deg,#ffffff26 25%,#0000 0,#0000 50%,#ffffff26 0,#ffffff26 75%,#0000 0,#0000);
  background-size: 1rem 1rem;
}

.yellow-progress-bar {
  background: rgba(251, 255, 0, 0.747);
  background-image: linear-gradient(45deg,#ffffff26 25%,#0000 0,#0000 50%,#ffffff26 0,#ffffff26 75%,#0000 0,#0000);
  background-size: 1rem 1rem;
}

.green-progress-bar {
  background: rgba(42, 202, 77, 0.62);
  background-image: linear-gradient(45deg,#ffffff26 25%,#0000 0,#0000 50%,#ffffff26 0,#ffffff26 75%,#0000 0,#0000);
  background-size: 1rem 1rem;
}

.div-progress-pointer {
  width: 50px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -25px;
  z-index: 10;
  border-radius: 0.375rem;
  background-color: #172b4d;
  opacity: 0;
  -webkit-transition: opacity 100ms ease-in-out;
  -moz-transition: opacity 100ms ease-in-out;
  -ms-transition: opacity 100ms ease-in-out;
  -o-transition: opacity 100ms ease-in-out;
  transition: opacity 100ms ease-in-out;
}

.div-progress-pointer span {
  color: white;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div-progress-pointer-base {
  width: 10px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  z-index: 10;
  background-color: transparent;
  border-top: 5px solid #172b4d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  opacity: 0;
  -webkit-transition: opacity 100ms ease-in-out;
  -moz-transition: opacity 100ms ease-in-out;
  -ms-transition: opacity 100ms ease-in-out;
  -o-transition: opacity 100ms ease-in-out;
  transition: opacity 100ms ease-in-out;
}

.ProgressBar:hover .div-progress-pointer, .ProgressBar:hover .div-progress-pointer-base {
  opacity: 1;
}