.div_map_input_floating_panel {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    transition: all 300ms ease-in-out;
    height: 70vh;
    margin-top: 1rem;
    max-height: 400px;
}

.div_align_map_input_floating_panel {
    overflow: visible !important;
    height: 100%;
    position: relative;
}

.div_error_load_map {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.div_error_load_map p, .div_error_load_map h6 {
    color: var(--seventhcolor);
}