.GPXFileInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 13vh;
  max-height: 180px;
}

.btn_remove_file_gpx {
  background-color: #2C2C2C;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.div_align_file_input {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 100%;
}

.div_label_input_file {
  height: 13vh;
  max-height: 80px;
  width: 18%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 1.5rem;
}

.div_field_input_file_type {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 40px;
  background-color: transparent;
  border-radius: 0.25rem;
}

.div_align_input_file {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.div_space_input_gpx_file {
  margin: 0;
  padding: 0.5rem;
  color: var(--eighthcolor);
  border: 0.5px solid var(--twenthcolor);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  transition: all 200ms ease-in-out;
  outline: none;
  width: 100%;
  height: 60px;
  border-radius: 0.25rem;
  font-size: 0.9rem;
}

.div_align_image_input {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-height: 20vh;
  max-height: 290px;
}

.div_align_input_file button {
  height: 100%;
  align-self: center;
  justify-self:center;
}

.div_field_input_file {
  margin: 0;
  border: 0.5px solid var(--twenthcolor);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  transition: all 200ms ease-in-out;
  border-radius: 0.25rem;
  display: flex;
  width: 100%;
  align-self: center;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 25vh;
}

.div_align_field_file {
  padding: 0 !important;
  padding-top: 0;
  height: 10vh !important;
  max-height: 20px !important;
  background-color: var(--eleventhcolor);
}

.input_namefile_fileinput {
  height: 100% !important;
  width: 90%;
  background-color: transparent;
  padding-top: 0;
  margin: 0;
  padding-left: 1rem;
  border: none;
}

.div_explanation_input .error-message-form-cae {
  margin-top: 0 !important;
  text-align: left;
}