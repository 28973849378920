.PopupConfirmExperience {
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.32);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .inner-popup-confirm-experience {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 10px;
    width: 500px;
    height: 220px;
    overflow: hidden;
  }
  
  .div-align-info-popup-confirm-experience {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    height: 60px;
    background-color: #172B4D;
    padding-left: 25px;
  }
  
  .div-align-info-popup-confirm-experience span:first-child {
    color: white;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
  }
  
  .div-align-button-confirm-experience {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: fit-content;
    gap: 10px;
    margin-right: 30px;
    margin-bottom: 15px;
  }
  
  .div-align-button-confirm-experience button {
    width: 120px;
    height: 40px;
    border-radius: 10px;
    background: #172B4D;
    color: #FFF;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: none;
    outline: none;
  }
  
  .div-align-button-confirm-experience button:active, .div-align-button-confirm-experience button:focus {
    outline: none;
  }
  
  .div-align-button-delete-experience {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
  }
  
  .div-align-button-delete-experience button:first-child {
    background-color: white;
    color: #172B4D;
    border: 2px solid #172B4D;
    width: 120px;
  }
  
  .div-align-button-delete-experience button:last-child {
    width: 140px;
  }
  
  .div-align-button-confirm-experience button:first-child {
    background-color: white;
    color: #172B4D;
    border: 2px solid rgba(0, 0, 0, 0.205);
    width: 120px;
  }

  .div-align-button-delete-experience button:last-child:disabled {
    background-color: #0d1d38;
    cursor: default;
  }

  .div-align-button-confirm-experience button:first-child:disabled {
    background-color: #b8b8b8;
    cursor: default;
  }
  
  .div-align-button-confirm-experience button:last-child {
    width: 140px;
  }
  
  .div-align-linear-progress-bar {
    width: calc(100% - 50px);
    margin: 0 auto;
    height: 80px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

.info-span-popup-confirm-experience {
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: left;
  color: rgba(0, 0, 0, 0.54);
}

.initial-info-span-popup-confirm-experience {
  text-align: justify;
}