.edit_info_table_score {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
}

.icon_edit_info_table_score {
    width: 11.25px;
    align-self: center;
    cursor: pointer;
}

.aling_icons_edit_info_table_score {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    right: 10px;
}

.aling_icons_edit_info_table_score img:first-child {
    width: 15px;
}

.edit_info_table_score select, .edit_info_table_score input {
    outline: none;
    width: fit-content;
    text-align: center;
    width: 70%;
    height: 32px;
    border-radius: 5px;
    border: 1px solid #DDDDDD;
}
.edit_info_table_score input {
    -webkit-appearance: none;
}