.WarningPopup {
    width: 99.3%;
    height: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 auto;
    border-radius: 0.5rem;
    padding: 0.25rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.div_icon_warning_popup {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_icon_warning_popup {
    width: 10%;
}

.div_message_warning_popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon_warning_popup {
    width: 50%;
    margin: 0 auto;
}

.div_message_warning_popup p {
    height: fit-content;
    font-size: 15px;
    font-family: Lato;
    margin: 0;
    padding: 0;
}