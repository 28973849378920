.LoadingComponent {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  overflow: hidden;
}

.loaderComponent {
  margin: auto;
  border: 10px solid #EAF0F6;
  border-radius: 50%;
  border-top: 10px solid #2C2C2C;
  width: 100px;
  height: 100px;
  animation: spinner 4s linear infinite;
  margin: auto;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}