@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300&display=swap');

.waypointInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80px;
}

.div_explanation_waypoint_input {
  width: 75%;
  padding-top: 0.5rem;
}

.div_align_button_type_waypoint {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  border: 1px solid #C3C3C3;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 30px;
}

.div_button_type_waypoint {
  width: 130px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.div_button_type_waypoint span {
  color: #2C2C2C;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#typeSelectWaypoint {
  background-color: #2C2C2C;
}

#typeSelectWaypoint span{
  color: white;
}

#typeUnselectWaypoint {
  background-color: rgba(245, 245, 245, 0.85);
}

#typeUnselectWaypoint:hover {
  background-color: rgba(221, 221, 221, 0.85);
}

.subtext_explanation_waypoint_input {
  font-weight: normal;
  color: rgba(128, 128, 128, 0.849);
  width: 100%;
  font-size: 14px;
  margin: 0;
  padding-left: 25px;
  padding-bottom: 10px;
}

.btn_open_waypoint_map {
  color: white;
  background-color: #18A689;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  height: 60%;
  margin-left: 25px;
  transition: all 300ms ease-in-out;
  width: 5%;
  height: 2rem;
  padding: 0;
  transition: all 200ms ease-in-out;
}

.button_open_waypoint_map:hover {
  background-color: #0cddb3;
}

.div_align_cabecalho_waypoint {
  width: 100%;
  font-size: 14px;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: rgba(195, 195, 195, 0.37);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid #C3C3C3;
}

.div_cabecalho_waypoint {
  height: 60px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.short_waypoint {
  width: 20%;
}

.div_cabecalho_waypoint span {
  color: #2C2C2C;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.alignWaypointTop{
  display: block;
  width: 173px;
  border: 2px solid black;
  box-sizing: border-box;
  margin-bottom: 5px;
  margin-left: 167px;
  padding: 7px 14px;
  text-align: center;
}

.alignOrder{
  margin-left: 100px;
  padding: 7px;
  border: 2px solid black;
}

.alignWaypoint {
  padding: 7px 14px;
  border-right: 2px solid black;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.alignWaypointTitle {
  padding: 7px 144px;
  border-right: 2px solid black;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.alignWaypointRemove{
  padding: 7px 103.5px;
  border-bottom: 2px solid black;
}

.div_align_input_waypoint {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: rgba(245, 245, 245, 0.85);
  height: 60px;
}

.align_btn, .align_waypoint_btn {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.btn_ordenate {
  max-width: 24px;
  max-height: 24px;
}

.align_lat_input, .align_long_input {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align_lat_input span, .align_long_input span {
  color: #2C2C2C;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.align_title_input_waypoint{
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align_input_waypoint{
  padding: 0.5rem;
  color: #2C2C2C;
  border: 0.5px solid var(--twenthcolor);
  transition: all 200ms ease-in-out;
  outline: none;
  width: 90%;
  border-radius: 0.25rem;
  font-size: 0.9rem;
}

.remove_waypoint_btn{
  border: none;
  background-color: var(--ninethcolor);
  width: 40px;
  height: 40px;
  transition: transform 30ms ease-in-out, box-shadow 30ms ease-in-out, background-color 200ms ease-in-out;
  outline: none;
  border-radius: 0.25rem;
  font-size: 12px;
  color:antiquewhite;
}

.remove_waypoint_btn:hover {
  text-decoration: none;
  color: #4a81ad;
}

.remove_waypoint_btn:active{
  transform: translateY(3px);
  box-shadow: 0 4px rgba(0, 0, 0, 0.6);
  outline: none;
}