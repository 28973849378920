@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300;400;500&display=swap');

.Experiences {
  background-color: #F6F7FC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 45px;
}

.body-experiences {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 49px;
}

.div-header-experiences {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.div-header-experiences h1 {
  color: #172B4D;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div-create-experiences {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 14px;
}

.add-new-experience {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #172B4D;
  /* width: 143.311px; */
  width: 180px;
  height: 50px;
  flex-shrink: 0;
  color: #FFF;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 9px;
  align-items: center;
  transition: all 200ms ease-in-out;
}

.add-new-experience:hover {
  background: #111C30;
}

.add-new-experience:active, .add-new-experience:focus {
  outline: none;
}

.div-search-experiences {
  border: 2px solid #EEE;
  background: #FFF;
  width: 383px;
  height: 50px;
  flex-shrink: 0;
  display: flex;
  flex: row;
  justify-content: space-evenly;
}

.div-search-experiences input {
  width: calc(100% - 18px - 18px - 22px);
  border: none;
  color: #C5C5C5;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  padding-left: 15px;
}

.div-search-experiences input::placeholder {
  color: #C5C5C5;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div-search-experiences img {
  width: 22px;
}

.div-row-header-list {
  display: flex;
  flex-direction: row;
  height: 85px;
  justify-content: space-between;
  background-color: transparent;
  border-bottom: 2px solid #172B4D;
  align-items: center;
}

.div-row-body-list {
  width: 100%;
  height: 78px;
  flex-shrink: 0;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 200ms ease-in-out;
}

.div-row-body-list:hover {
  background: #EBECF0;
}

.div-row-header-list div {
  color: #172B4D;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.div-row-body-list div {
  color: #42526E;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section-short-list {
  width: 10%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-medium-list {
  width: 15%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-long-list {
  width: 30%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-option-list {
  width: 8%;
  min-width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.section-option-list button {
  border-radius: 5px;
  background: #172B4D;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border: none;
  transition: all 200ms ease-in-out;
}

.section-option-list button:hover {
  background: #111C30;
}

.section-option-list button:active, .section-option-list button:focus {
  outline: none;
}

.popup-options-list {
  width: 143px;
  flex-shrink: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  right: calc(50% - 20px);
  z-index: 10;
  top: -30px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: none;
}

.popup-options-list-larger {
  top: -78px !important;
}

.more-options-list {
  top: -75px;
}

.popup-options-list div {
  width: 100%;
  display: flex;
  height: 48px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}

.popup-options-list div:hover {
  background: #EEE;
}

.popup-options-list div span {
  color: #42526E;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup-options-list div img {
  width: 10px;
  height: 11.25px;
  flex-shrink: 0;
}

.div-calculator-experience {
  align-self: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #F6F7FC;
}

.red-classification-experience {
  background-color: rgba(239, 69, 69, 0.59);
}

.yellow-classification-experience {
  background: rgba(250, 255, 0, 0.69);
}

.green-classification-experience {
  background: rgba(57, 251, 100, 0.62);
}

