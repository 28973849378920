@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@100;300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@100;300;400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@100;300;400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300;8..144,400;8..144,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jaldi&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300;400;500&display=swap);
.container {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  width: 100%;
  display: flex;
  flex: 1 1;
  text-align: left;
}

.data-container {
  flex: 0.5 1;
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.pink-line {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom-color: #db3d78;
  border-bottom-style: solid;
  border-bottom-width: 7px;
  width: 35px;
}

.photo-container {
  flex: 0.5 1;
  display: flex;
  justify-content: center;
  align-items: center
}

.big-size-font  {
  margin: 0px;
  font-size: medium;
}

.medium-size-font  {
  margin: 0px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  font-size: small;
}

.small-size-font  {
  margin: 0px;
  font-size: smaller;
}
@media (max-width: 768px) {
  .slick-slider{ /*Wraps the carousel*/
    width: 100%;
  }
}

.slick-track{
  display: flex;
  flex: 1 1;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
.slick-arrow {
  background-color: grey;
}
.slick-arrow:hover {
  background-color: grey;
}
.slick-arrow:focus {
  background-color: grey;
}
.slick-arrow {
  background: inherit !important;
  z-index: 5 !important;
}
.slick-arrow:hover {
  opacity: 0.6 ;
}
.slick-next {
  margin-right: 15%;
}

.slick-prev {
  margin-left: 10%;
  left: 0px;
}
.gmnoprint {
  margin: 0px !important;
}

.gm-style-mtc div {
  height: 25px !important;
  padding: 0px 8px !important;
}
.loginAdm {
    align-items: center;
    background-color: #a26396;
    font-family: Calibri;
    height: 100vh;
    background-image: url(/static/media/desk_bg.5322fc12.png);
}

/* Top Navbar */
.top_navbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #d35887;
    height: 10vh;
    width: 100%;
    overflow: auto;
    margin: 0;
    overflow: hidden;
}

.div_elements_nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon_destinos {
    width: 55px;
}

.title_nav {
    font-family: 'Lato', sans-serif;
    color: white;
}

/* Body form login */
.content_login {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 40vh;
    margin: 0 auto;
    align-items: center;
    margin-top: 20%;
    text-align: center;
}

/* Form */
.title_form {
    font-family: 'Lato', sans-serif;
    font-style: italic;
    font-style: bold;
    color: white;
}

.form_login {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    height: 80%;
}

.body_form {
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

/* Fields */
.div_input_form {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: 30%;
}

.field_login {
    border: none;
    border-bottom: 1px solid #d35887;
    background: transparent;
    width: 80%;
    color: white;
}

.icon_email_form {
    width: 6%;
}

.icon_pass_form {
    width: 6%;
}

.icon_show_pass {
    width: 7%;
    position: absolute;
    align-self: center;
    padding: 2px;
    left: 80%;
    cursor: pointer;
}

.icon_show_pass:active {
    background-color: #d687c8da;
}

.field_login::placeholder {
    color: white;
}

.field_login:focus {
    outline: none;
    border-bottom: 1px solid black;
}

.field_login:-webkit-autofill,
.field_login:-webkit-autofill:hover, 
.field_login:-webkit-autofill:focus, 
.field_login:-webkit-autofill:active{
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: white !important;
}

.div_forgot_password_admin{
    margin: 0 auto;
    width: 70%;
    height:35%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.forgot_password_admin {
    color: white;
    cursor: pointer;
    width: 100%;
}

.forgot_password_admin:active {
    color: #cb3f22;
}

.btn_login_adm {
    background-color: #cb3f22;
    border: none;
    border-radius: 5px;
    height: 30%;
    width: 40%;
    margin: 0 auto;
    color: white;
    margin-top: 5%;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25));
    transition: background-color 0.5s, filter 0.5s;
    cursor: pointer;
}

.btn_login_adm:hover {
    background-color: #e65639;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition-delay: 100ms;
    transition-property: background-color, filter;
}

@media only screen and (min-width: 495px) { 
    /* Body form login */
    .content_login {
        width: 70%;
        margin-top: 10%;
    }

    .form_login {
        width: 70%;
    }

    .icon_show_pass {
        width: 4%;
        left: 67%;
    }   
}

@media only screen and (min-width: 1000px) { 
    /* Body form login */
    .content_login {
        width: 60%;
        margin-top: 8%;
    }

    .form_login {
        width: 50%;
        height: 40vh;
    }

    .body_form {
        height: 40vh;
    }

    .icon_show_pass {
        width: 2.3%;
        left: 61%;
    }
}

@media only screen and (min-width: 2150px) { 
    .icon_show_pass {
        width: 1.8%;
        left: 61%;
    }  
    * {
        font-size: 105%;
    }
}

@media only screen and (min-width: 3150px) { 
    .icon_show_pass {
        width: 1.8%;
        left: 61%;
    }  
    * {
        font-size: 115%;
    }

    .title_nav {
        font-size: 170%;
    }
    .icon_destinos {
        width: 170px;
    }
    .title_form {
        font-size: 150%;
    }
}
.CaeLogin {
  background: #EBF1FF;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  padding-top: 107px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main-container-cae-login {
  width: 1172px;
  height: 658px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 28px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  overflow: hidden;
}

.container-cae-login {
  width: 50%;
  height: 100%;
}

.header-container-cae-login {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  grid-gap: 2px;
  gap: 2px;
}

.container-cae-login:first-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  padding: 20px;
  padding-top: 51px;
  grid-gap: 52px;
  gap: 52px;
}

.header-container-cae-login span:first-child {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header-container-cae-login span:last-child {
  color: #42526E;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.title-container-cae-login {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.header-container-cae-login img {
  width: 100%;
}

.title-container-cae-login h1 {
  color: #172B4D;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.title-container-cae-login span {
  color: #42526E;
  font-family: Inter;
  font-size: 14.7px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.div-align-fields-cae-login {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  grid-gap: 20px;
  gap: 20px;
  padding-top: 4px;
  align-items: center;
  margin: 0 auto;
}

.div-button-cae-login {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: -22px;
}

.div-button-cae-login button {
  border-radius: 30px;
  background: #172B4D;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 124px;
  height: 43px;
  border: none;
  color: #FFF;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  outline: none;
  transition: all 200ms ease-in-out;
}

.div-button-cae-login button:hover {
  background: #111C30;
}

.div-button-cae-login button:active, .div-button-cae-login button:focus {
  outline: none;
}

.hr-div-cae-login {
  width: 73%;
  margin: 0 auto;
  height: 2px;
  background: #EBECF0;
  margin-top: -20px;
}

.div-create-account-cae-login {
  width: 73%;
  margin: 0 auto;
  text-align: center;
  margin-top: -36px;
}

.div-create-account-cae-login span {
  color: #42526E;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.div-create-account-cae-login a {
  color: #172B4D;
  cursor: pointer;
  font-weight: 400;
}

.container-cae-login:last-child {
  background-image: url(/static/media/logo_login_embratur_background.d42538ae.svg);
}

.error-message-login {
  text-align: center;
  padding-top: 15px;
}

.message-notify-reset-password {
  color: #42526E;
  font-family: Inter;
  font-size: 14.7px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.footer-logo-destinos {
  width: 73%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100px;
  grid-gap: 10px;
  gap: 10px;
  margin-top: -15px;
}

.footer-logo-destinos img {
  width: 90px;
}

.footer-logo-destinos span {
  color: #42526E;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}


@media only screen and (max-width: 1200px) {
  .main-container-cae-login {
    width: 550px;
  }

  .container-cae-login:first-child {
    width: 100%;
  }

  .container-cae-login:last-child {
    display: none;
  }
}

@media only screen and (max-width: 660px) {
  .CaeLogin {
    padding-top: 0;
  }

  .main-container-cae-login {
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
  }

  .container-cae-login:first-child {
    width: 100%;
    height: 100%;
  }

  .main-container-cae-login {
    border-radius: 0;
  }

  .container-cae-login:last-child {
    display: none;
  }
  
  .header-container-cae-login, .title-container-cae-login, .div-align-fields-cae-login, .div-button-cae-login, .div-create-account-cae-login, .hr-div-cae-login, .footer-logo-destinos {
    width: 90%;
  }

  .div-form-cae-register div {
    width: 100% !important;
  }
}
.TextField {
  border-radius: 12px;
  border: 1px solid #EBECF0;
  background: #EBF1FF;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 70%;
  height: 40px;
  flex-shrink: 0;
  padding-left: 2px;
}

.TextField img {
  width: 14px;
  align-self: center;
}

.TextField input {
  width: 76%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.TextField input::placeholder {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.FooterLoginCae {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 11px;
  gap: 11px;
  margin-top: 50px;
}

.FooterLoginCae span {
  color: #172B4D;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.div-align-social-media {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 18px;
  gap: 18px;
}

.div-align-social-media img {
  cursor: pointer;
  width: 20px;
}

@media only screen and (max-width: 575px) {
  .FooterLoginCae {
    display: none;
  }
}
.InventarioAdmin {
    height: 100%;
    width: 100%;
    background-color: var(--secondcolor);
    padding-left: 1.5%;
    padding-top: 2%;
    font-size: 1vw;
    
}

.div_title_inventory_adm h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 2.3vw;
}

.create_inventory_adm {
    color: #8ac0ec;
    cursor: pointer;
    font-size: 1.3vw;
    width: 20%;
}

.create_inventory_adm:hover {
    color: #4a81ad;
}

.div_browse_inventory_adm {
    width: 50%;
    height: 55px;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    border: 2px solid var(--sixthcolor);
    border-radius: 5px;
    overflow: hidden;
    padding: 0;
    transition: all 300ms ease-in-out;
    box-shadow: -5px 3px 5px rgba(0, 0, 0, 0.19);
}

.div_browse_inventory_adm:focus-within {
    border: 2px solid var(--ninethcolor);
    box-shadow: -8px 6px 5px rgba(0, 0, 0, 0.19);
}

.div_browse_inventory_adm input, .div_browse_inventory_adm button {
    height: 100%;
    margin: 0;
    border: none;
    outline: none;
}

.div_browse_inventory_adm input::placeholder {
    color: var(--sixthcolor);
}

.div_browse_inventory_adm input {
    width: 90%;
    padding: 1rem;
    font-size: 1rem;
    color: var(--sixthcolor);
}

.div_browse_inventory_adm button {
    width: 10%;
    background: none;
    background-color: white;
    border-radius: 0;
}

.title_browse_inventory {
    height: 100%;
    margin: 2px;
    font-size: 1.1vw;
}

.input_browse_inventory {
    height: 100%;
    margin: 2px;
    font-size: 14px;
}

.btn_browse_inventory_adm {
    height: 27px;
    margin: 2px;
    border: 1px solid gray;
    font-size: 12px;
    border-radius: 10%;
}

.div_table_inventory_adm {
    width: 100%;
    min-height: 70vh;
}

.table_inventory_adm {
    width: 99.5%;
    height: 100%;
    font-size: 1.2vw;
    color: rgba(128, 128, 128, 0.849);
    box-shadow: -8px 4px 5px rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    overflow: hidden;
}

.table_inventory_adm td {
    height: 100%;
}

.row_table_inventory {
    border-bottom: 1px solid rgba(128, 128, 128, 0.164);
    height: 20vh;
}

.th_table_admin {
    height: 6rem;
}

.column_title_table_inventory {
    width: 10%;
    padding: 7px;
    color: black;
}

.column_check_table_inventory {
    width: 8%;
}

.column_desc_table_inventory {
    width: 34%;
    padding: 7px;
    color: black;
}

.column_table_inventory {
    width: 5%;
    padding: 7px;
    text-align: left;
    color: black;
}

.column_title_table_inventory, .column_desc_table_inventory, .column_table_inventory {
    cursor: pointer;
}

.column_title_table_inventory:hover, .column_desc_table_inventory:hover, .column_table_inventory:hover {
    color: #4a81ad;
}

.column_edit_table_inventory {
    width: 5%;
    display: flex;
    padding: 7px;
    flex-direction: column;
    justify-content: space-evenly;
}

.div_pagination_inventory {
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn_pagination_inventory {
    background-color: transparent;
    cursor: pointer;
    border: none;
    width: 5%;
    font-size: 22px;
}

.btn_pagination_inventory:hover {
    background-color: rgba(128, 128, 128, 0.24);
}

.th_table_admin {
    background: linear-gradient(
    180deg,
    var(--fifthcolor) 40%,
    var(--fourthcolor) 100%
    );
    color: white;
}

.th_table_admin label, .th_table_admin input {
    cursor: pointer;
}

.div_align_pendencies {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.div_review_manyPendencies, .div_select_all_pendencies {
    opacity: 0;
    transition: all 150ms ease-in-out;
}

.div_review_manyPendencies {
    height: 55px;
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #2d8f7b;
    box-shadow: -5px 3px 5px rgba(0, 0, 0, 0.19);
    border-radius: 1rem;
    margin-left: 1rem;
}

.div_review_manyPendencies p {
    color: white;
    font-size: 1rem;
    font-weight: 100;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_review_manyPendencies button {
    height: 80%;
    width: 20%;
    border-radius: 1rem;
    justify-self: center;
    align-self: center;
    transition: all 150ms ease-in-out;
    outline: none;
}

.div_review_manyPendencies button:focus {
    outline: none;
}

#btn_approve_pendency {
    background-color: white;
    color: #2d8f7b;
    border: 2px solid #2d8f7b;
}

#btn_approve_pendency:hover {
    border-color: white;
    color: white;
    background: transparent;
}

#btn_refuse_pendency {
    border: 2px solid white;
    color: white;
    background: transparent;
}

#btn_refuse_pendency:hover {
    background-color: white;
    color: #2d8f7b;
    border-color: #2d8f7b;
}

.div_select_all_pendencies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.label_check_all_pendencies {
    height: 10px;
}

/* Columns */
.row_table_inventory td {
    text-align: center;
}

.body_table {
    background-color: var(--firstcolor);
}

.title_table_inventory {
    width: 10%;
    padding: 7px;
}

.desc_table_inventory {
    width: 34%;
    padding: 7px;
}

.column_min_table_inventory {
    width: 5%;
    height: 100%;
}

/* Div's buttons */
.div_mod_inventory {
    height: 100%;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Buttons */
.btn_mod_adm {
    cursor: pointer;
    border: none;
    background: transparent;
    color: #8ac0ec;
    margin: 5px;
}

.btn_mod_adm:hover {
    text-decoration: none;
    color: #4a81ad;
}

.button-view-pendency-item-user {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #18A689;
    border-radius: 5px;
    font-size: 16px;
    color: white;
    outline: none;
    border: none;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
}

.button-view-pendency-item-user:hover {
    background-color: #23c9a8;
}
.EditInventory {
    min-height: 80vh;
}

.form_edit_admin {
    margin-top: 1rem;
    border-top: 5px solid var(--eleventhcolor);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--firstcolor);
}

.div_titles_edit_inventory h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 32px;
    padding: 4px;
    margin: 0;
}

.div_titles_edit_inventory h4 {
    font-size: 16px;
    padding: 6px;
}

/* All divs components*/
.div_edit_textarea_type, .div_edit_text_type, .div_edit_elements_type, .div_edit_select_type, .div_edit_radio_type, .div_edit_checkbox_type, .div_edit_image_type {
    margin: 0;
    padding: 5px;
}

.div_add_act {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: white;
}

.div_edit_radio_type, .div_edit_checkbox_type, .div_edit_checkbox_column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.div_edit_checkbox_type {
    width: 50%;
}

.div_edit_checkbox_row {
    padding: 5px;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.div_edit_checkbox_row_segments {
    grid-template-columns: 33.3% 33.3% 33.3%;
}

.div_edit_checkbox_row_comtur {
    grid-template-columns: 100%;
}

.div_edit_checkbox_row div {
    width: 100%;
}


.div_btn_edit_inventory {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 45%;
    height: 10vh;
}

.div_edit_image_type {
    height: 15vh;
}

.div_edit_map_type {
    height: 70vh;
    width: 50%;
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_input_geolocation {
    width: 45%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
    border: 0.5px solid #e8e9ea;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.txt_title_inventory, .subtext_inventory, .div_label_inventory label, .div_edit_checkbox_type label {
    font-weight: bold;
    color: #767676;
    font-size: 14px;
    margin: 0;
    padding: 2px;
}

.subtext_inventory {
    font-weight: normal;
    color: rgba(128, 128, 128, 0.849);
    width: 45%;
}

.input_text_inventory, .textarea_inventory, .select_inventory, .input_add_act {
    font-weight: lighter;
    font-size: 13px;
    width: 45%;
    margin: 0;
    padding: 6px;
    color: rgba(128, 128, 128, 0.849);
    border: 0.5px solid #e8e9ea;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
}

.input_geolocation {
    font-weight: lighter;
    font-size: 13px;
    color: rgba(128, 128, 128, 0.849);
    outline: none;
    background: none;
    border: none;
    width: 92%;
    padding: 6px;
}

.div_input_geolocation:focus-within {
    border: 1.5px solid #18A689;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.textarea_inventory:focus, .input_text_inventory:focus, .select_inventory:focus, .input_add_act:focus {
    border: 1.5px solid #18A689;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.input_add_act {
    width: 80%;
}

.textarea_inventory {
    height: 10vh;
    border: 0.5px solid rgba(128, 128, 128, 0.849);
}

.select_inventory {
    font-size: 15px;
}

.radio_input_inventory:checked, .radio_input_inventory.with-gap:checked {
    background-color: #767676;
}

.btn_edit_inventory, .btn_image_inventory, .btn_add_act, .btn_open_map {
    color: white;
    background-color: #18A689;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    width: 17%;
    height: 60%;
    margin: 5px;
    transition: all 300ms ease-in-out;
}

.btn_add_act {
    width: 20%;
    height: 32px;
    border-radius: 0px;
    align-self: center;
    margin: 0;
    padding: 0;
}

.btn_open_map {
    width: 8%;
    height: 2rem;
    margin: 0;
    padding: 0;
    transition: all 200ms ease-in-out;
}


.btn_image_inventory {
    width: 12%;
    height: 20%;
    transition: all 300ms ease-in-out;
}

.btn_image_inventory:focus {
    outline: none;
}

.btn_image_editCity {
    height: 2.4rem;
    transition: all 300ms ease-in-out;
}

.input_file_inventory {
    display: none;
}

.div_edit_image_type {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 45vh;
}

.div_image_editCity {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.div_edit_list_images {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 45%;
    height: 90%;
    background-color: white;
    overflow: auto;
    text-align: center;
}

.div_edit_list_images p {
    align-self: center;
    justify-self: center;
    font-weight: normal;
    font-size: 15px;
    color: rgba(128, 128, 128, 0.849);
}

.div_edit_list_images::-webkit-scrollbar {
    background: white;
    height: 7px;
}

.div_edit_list_images::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.849);
    border-radius: 40px;
    height: 7px;
}

.btn_edit_inventory:hover,  .btn_image_inventory:hover, .btn_add_act:hover, .btn_open_map:hover{
    background-color: #0cddb3;
}

.div_activities_edit {
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    width: 45.5%;
    height: 15vh;
}

.div_select_neighborhood_region {
    width: 100%;
}

.div_selects_neigh_align select::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 3px;
}

.div_selects_neigh_align select::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
    border-radius: 5px;
}



.img_arrow_region {
    background: none;
    width: 100%;
}

.img_map_btn {
    width: 98%;
    height: 100%;
}

.div_align_multiple_select {
    width: 100%;
    padding: 0;
}

.div_align_multiple_select select {
    width: 100%;
}

.div_values_multiple_select select{
    height: 100%;
}

.divisor_component {
    border-top: 1px dashed var(--eleventhcolor);
    width: 95%;
    text-align: center;
}
.Loading {
    width: 82.8%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f3f3f4;
    overflow: hidden;
    z-index: 1000;
}

.loader {
    margin: auto;
    border: 15px solid #EAF0F6;
    border-radius: 50%;
    border-top: 15px solid #18A689;
    width: 150px;
    height: 150px;
    animation: spinner 4s linear infinite;
    margin: auto;
  }
  
@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.VerifyPopup {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.32);;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: auto;
    z-index: 10;
}

.div-align-inner-popup {
    width: 500px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}

.div-title-verify-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 80px;
}

.div-title-verify-popup span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: #797979;
    text-align: center;
}

.div-message-verify-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 180px;
}

.div-message-verify-popup span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #616161;
}

.div-buttons-verify-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
    grid-gap: 20px;
    gap: 20px;
}

.div-buttons-verify-popup button {
    width: 70%;
    height: 50px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    border-radius: 25px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 100ms ease-in-out;
    cursor: pointer;
    outline: none;
}

.div-buttons-verify-popup button:active {
    outline: none;
}

.btn-confirm-verify-popup:disabled {
    background-color: var(--fifteenthcolor) !important;
}

..btn-cancel-verify-popup:disabled {
    background-color: #d4d4d4 !important;
}

.btn-cancel-verify-popup {
    background-color: #EEEEEE;
    color: #797979;
}

.btn-cancel-verify-popup:hover {
    background-color: #d4d4d4;
}

.btn-confirm-verify-popup {
    background-color: var(--ninethcolor);
    color: white;
}

.btn-confirm-verify-popup:hover {
    background-color: var(--fifteenthcolor);
}
.spin-outside {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 2px solid white;
    border-bottom-color: var(--sixthcolor);
    border-radius: 50%;
    animation: spinner 2s linear infinite;
    margin: 0 auto;
    align-self: center;
    justify-self: center;
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.TitleEditor {
    width: 100%;
    background-color: var(--firstcolor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 18vh;
    padding: 0;
}

.div_top_card {
    width: 100%;
    height: 3%;
    background-color: var(--eleventhcolor);
}

.div_align_title_editor {
    width: 100%;
    height: 97%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    padding: 1rem;
}

.div_align_title_editor h2 {
    font-family: 'DM Sans', sans-serif;
    font-weight: 100;
    font-size: 1.5rem;
}

.div_align_title_editor h6 {
    font-family: 'DM Sans', sans-serif;
    font-weight: 100;
    font-size: 1rem;
    color: var(--seventhcolor);
}
.HeaderForm {
    width: 100%;
    height: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 1rem;
    border-bottom: 2px solid var(--secondcolor);
}

.HeaderQuestion {
    min-height: 8vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.HeaderPlaceId {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
}

.HeaderForm p {
    font-family: 'DM Sans', sans-serif;
    font-size: 0.75rem;
    font-weight: 100;
    margin: 0;
    padding: 0;
    color: var(--seventhcolor);
}

.div_align_handle_question_header {
    width: 8.5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.div_handle_question_header {
    width: 100% !important;
}

.title_question {
    justify-self: center;
    align-self: center;
}

.HeaderForm strong {
    font-size: 0.9rem;
    font-weight: 700;   
    color: var(--eighthcolor);
}

.btn_handle_question {
    width: 100% !important;
}

.img_handle_size_question {
    transition: all 300ms ease-in-out;
}

.btn_get_place_id {
    border: none;
    border-radius: 5px;
    background-color: var(--thirdcolor);
    color: white;
    font-size: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 150px;
    height: 40px;
    outline: none;
}

.btn_get_place_id:hover {
    background-color: var(--fourthcolor);
}
.ButtonForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 13vh;
}

.div_out_button_form {
    width: 84.7%;
    height: 10vh;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_align_button_form {
    width: 94.45%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 0.2rem;
    gap: 0.2rem;
}

.div_align_button_form button {
    height: 100%;
    max-height: 2.5rem;
    justify-self: center;
    align-self: center;
    margin: 0;
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    border-radius: 0.25rem;
    outline: none;
}

.div_align_button_form button:focus {
    outline: none;
}

.btn_cancel_changes {
    background-color: var(--firstcolor) !important;
    color: rgba(128, 128, 128, 0.849) !important;
    max-width: 6rem;
}

.btn_cancel_changes:hover {
    background-color: var(--twenthcolor) !important;
}
.div_map_input {
    width: 90%;
    margin: 0 auto;
    height: 0;
    overflow: hidden;
    transition: all 300ms ease-in-out;
}

.div_align_map_input {
    overflow: visible !important;
    height: 100%;
    position: relative;
}

.div_error_load_map {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.div_error_load_map p, .div_error_load_map h6 {
    color: var(--seventhcolor);
}
.GeolocationInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80px;
}

.div_align_input_geolocation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_label_input_geolocation {
    width: 18% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1.5rem;
}

.div_field_input_geolocation {
    margin: 0;
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    width: 100%;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.div_field_input_geolocation input {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 200ms ease-in-out;
    width: 100%;
    padding: 0.5rem;
    color: var(--eighthcolor);
}

.div_field_input_geolocation input:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

.div_field_input_geolocation input::placeholder {
    color: var(--eighthcolor);
}

.div_field_input_geolocation button {
    width: 45px;
    height: 35px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.div_field_input_geolocation button img {
    width: 90%;
}
.GeolocationInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80px;
}

.div_align_input_geolocation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_label_input_geolocation {
    width: 13%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1.5rem;
}

.div_field_input_geolocation {
    margin-bottom: 5px;
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    width: 100%;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.div_field_input_geolocation input {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 200ms ease-in-out;
    width: 100%;
    padding: 0.5rem;
    color: var(--eighthcolor);
}

.div_field_input_geolocation input:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

.div_field_input_geolocation input::placeholder {
    color: var(--eighthcolor);
}

.buttondDelete{
    padding: 0;
    border: none;
    background: none;
    font-size: 20px;
    outline: none;
    color: gray;
}

.btn_input_arquive::file-selector-button {
    border: none;
    background-color: var(--ninethcolor);
    box-shadow: 0 1px rgba(0, 0, 0, 0.4);
    transition: transform 30ms ease-in-out, box-shadow 30ms ease-in-out, background-color 200ms ease-in-out;
    outline: none;
    border-radius: 0.25rem;
    font-size: 12px;
    color:antiquewhite;
}

.btn_input_arquive::file-selector-button:hover{
    background-color: #0cddb3;
    cursor: pointer;
}

.btn_input_arquive::file-selector-button:active{
    transform: translateY(3px);
    box-shadow: 0 4px rgba(0, 0, 0, 0.6);
    outline: none;
  }
  
.btn_input_arquive::file-selector-button:focus{
    outline: none;
  }

.btn_open_waypoint {
    border: none;
    background-color: var(--ninethcolor);
    box-shadow: 0 1px rgba(0, 0, 0, 0.4);
    outline: none;
    border-radius: 0.25rem;
    font-size: 60%;
    color:antiquewhite;
    width: 17%;
    height: 60%;
    margin: 5px;
    transition: all 300ms ease-in-out;
}

.btn_open_waypoint :hover{
    background-color: #0cddb3;
}

.btn_open_waypoint:active{
    transform: translateY(3px);
    box-shadow: 0 4px rgba(0, 0, 0, 0.6);
    outline: none;
  }
  
.btn_open_waypoint:focus{
    outline: none;
  }

.coordinates-box {
    max-height: 200px; /* Set the maximum height you desire */
    overflow-y: auto; /* Add vertical scrollbar when content exceeds max height */
    border: 1px solid #ccc; /* Optional border for styling */
    padding: 10px; /* Optional padding for styling */
}
.waypointInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80px;
}

.div_explanation_waypoint_input {
  width: 75%;
  padding-top: 0.5rem;
}

.div_align_button_type_waypoint {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  border: 1px solid #C3C3C3;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 30px;
}

.div_button_type_waypoint {
  width: 130px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.div_button_type_waypoint span {
  color: #2C2C2C;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#typeSelectWaypoint {
  background-color: #2C2C2C;
}

#typeSelectWaypoint span{
  color: white;
}

#typeUnselectWaypoint {
  background-color: rgba(245, 245, 245, 0.85);
}

#typeUnselectWaypoint:hover {
  background-color: rgba(221, 221, 221, 0.85);
}

.subtext_explanation_waypoint_input {
  font-weight: normal;
  color: rgba(128, 128, 128, 0.849);
  width: 100%;
  font-size: 14px;
  margin: 0;
  padding-left: 25px;
  padding-bottom: 10px;
}

.btn_open_waypoint_map {
  color: white;
  background-color: #18A689;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  height: 60%;
  margin-left: 25px;
  transition: all 300ms ease-in-out;
  width: 5%;
  height: 2rem;
  padding: 0;
  transition: all 200ms ease-in-out;
}

.button_open_waypoint_map:hover {
  background-color: #0cddb3;
}

.div_align_cabecalho_waypoint {
  width: 100%;
  font-size: 14px;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: rgba(195, 195, 195, 0.37);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid #C3C3C3;
}

.div_cabecalho_waypoint {
  height: 60px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.short_waypoint {
  width: 20%;
}

.div_cabecalho_waypoint span {
  color: #2C2C2C;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.alignWaypointTop{
  display: block;
  width: 173px;
  border: 2px solid black;
  box-sizing: border-box;
  margin-bottom: 5px;
  margin-left: 167px;
  padding: 7px 14px;
  text-align: center;
}

.alignOrder{
  margin-left: 100px;
  padding: 7px;
  border: 2px solid black;
}

.alignWaypoint {
  padding: 7px 14px;
  border-right: 2px solid black;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.alignWaypointTitle {
  padding: 7px 144px;
  border-right: 2px solid black;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.alignWaypointRemove{
  padding: 7px 103.5px;
  border-bottom: 2px solid black;
}

.div_align_input_waypoint {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: rgba(245, 245, 245, 0.85);
  height: 60px;
}

.align_btn, .align_waypoint_btn {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.btn_ordenate {
  max-width: 24px;
  max-height: 24px;
}

.align_lat_input, .align_long_input {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align_lat_input span, .align_long_input span {
  color: #2C2C2C;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.align_title_input_waypoint{
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align_input_waypoint{
  padding: 0.5rem;
  color: #2C2C2C;
  border: 0.5px solid var(--twenthcolor);
  transition: all 200ms ease-in-out;
  outline: none;
  width: 90%;
  border-radius: 0.25rem;
  font-size: 0.9rem;
}

.remove_waypoint_btn{
  border: none;
  background-color: var(--ninethcolor);
  width: 40px;
  height: 40px;
  transition: transform 30ms ease-in-out, box-shadow 30ms ease-in-out, background-color 200ms ease-in-out;
  outline: none;
  border-radius: 0.25rem;
  font-size: 12px;
  color:antiquewhite;
}

.remove_waypoint_btn:hover {
  text-decoration: none;
  color: #4a81ad;
}

.remove_waypoint_btn:active{
  transform: translateY(3px);
  box-shadow: 0 4px rgba(0, 0, 0, 0.6);
  outline: none;
}
.div_map_input_floating_panel {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    transition: all 300ms ease-in-out;
    height: 70vh;
    margin-top: 1rem;
    max-height: 400px;
}

.div_align_map_input_floating_panel {
    overflow: visible !important;
    height: 100%;
    position: relative;
}

.div_error_load_map {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.div_error_load_map p, .div_error_load_map h6 {
    color: var(--seventhcolor);
}
.LoadingComponent {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  overflow: hidden;
}

.loaderComponent {
  margin: auto;
  border: 10px solid #EAF0F6;
  border-radius: 50%;
  border-top: 10px solid #2C2C2C;
  width: 100px;
  height: 100px;
  animation: spinner 4s linear infinite;
  margin: auto;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.TextareaInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 250px;
}

.div_align_textareaInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;
}

.div_label_input_textarea {
    width: 18%;
    height: 13vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
}

.label_textarea_input {
    width: 100%;
    text-align: right;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    padding-top: 2rem;
    margin: 0;
}

.div_out_input_textarea {
    width: 100%;
    min-height: 30vh;
    /* max-height: 150px; */
    align-self: center;
    display: block;
    flex-direction: column;
    justify-content: center;
}
 
.textarea_input_comp, .div_out_input_textarea textarea {
    margin: 0;
    padding: 0.5rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 100%;
    min-height: 30vh;
    border-radius: 0.25rem;
    font-size: 0.9rem;
}

.textarea_input_comp:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

.div_align_explanation_textarea {
    min-height: 100%;
    display: inline-block;
    flex-direction: column;
    justify-content: center;
}

.div_explanation_textarea {
    padding-top: 0.5rem;
    height: 30px;
}
.RadioInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-height: 80px;
}

.div_label_input_radio {
    width: 18%;
    height: 13vh;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
}

.label_radio_input {
    width: 100%;
    text-align: right;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    padding: 0;
    margin: 0;
}

.div_out_input_radio {
    width: 75%;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 2rem;
    gap: 2rem;
}

.div_align_radio_input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 5vh;
}

.div_align_radio_input label {
    justify-self: center;
    align-self: center;
}

.label_text_radiomark {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-self: center;
    margin: 0;
    font-weight: 400;
    font-style: bold;
    color: var(--eighthcolor);
    font-size: 1rem;
    margin-left: 0.8rem;
}


.label_radiomark_input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    min-height: 26px;
    width: 26px;
    padding: 0;
    margin: 0;
}

.label_radiomark_input input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.radiomark_radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: #eee;
    border: 2px solid #eee;
    transition: all 100ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;
}

.label_radiomark_input:hover input ~ .radiomark_radio {
    background-color: #ccc;
    border-color: var(--thirteenthcolor);
}

.label_radiomark_input input:checked ~ .radiomark_radio {
    background-color: var(--thirteenthcolor);
    border-color: var(--thirteenthcolor);
}

.radiomark_radio:after {
    content: "";
    position: absolute;
    display: none;
}

.label_radiomark_input input:checked ~ .radiomark_radio:after {
    display: block;
}

.label_radiomark_input .radiomark_radio:after {
    align-self: center;
    justify-self: center;
    margin-top: -15%;
    width: 35%;
    height: 60%;
    border: 1px solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.label_text_radiomark {
    cursor: pointer;
}
.CheckboxRender {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
}

.div_label_input_checkbox {
    width: 18%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 1rem;
}


.div_align_checkbox {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_checkbox_row_grid {
    width: 100%;
    display: grid;
    justify-content: flex-start;
    grid-template-columns: 30% 30%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.div_checkbox_row_grid_2 {
    width: 100%;
    display: grid;
    justify-content: flex-start;
    grid-template-columns: 50% 50% !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-gap: 1rem;
    gap: 1rem;
}
.div_checkbox_input {
    width: 100%;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.label_checkbox_input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    height: 1.5rem;
    width: 1.5rem;
}

.label_checkbox_input input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark_input {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #eee;
    border: 2px solid #eee;
    transition: all 100ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;    
}

.label_checkbox_input:hover input ~ .checkmark_input {
    background-color: #ccc;
    border-color: #2d8f7b;
}

.label_checkbox_input input:checked ~ .checkmark_input {
    background-color: #2d8f7b;
    border-color: #2d8f7b;
}

.checkmark_input:after {
    content: "";
    position: absolute;
    display: none;
}

.label_checkbox_input input:checked ~ .checkmark_input:after {
    display: block;
}

.label_checkbox_input .checkmark_input:after {
    align-self: center;
    justify-self: center;
    margin-top: -15%;
    width: 35%;
    height: 60%;
    border: 1px solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.title_checkbox_input {
    padding-left: 1rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: var(--eighthcolor);
    cursor: pointer;
}

.input_other_option {
    width: 0;
    height: 1.5rem;
    align-self: flex-start;
    margin-left: 1rem;
    border: none;
    font-size: 1rem;
    color: var(--eighthcolor);
    background-color: transparent;
    transition: all 300ms ease-in-out;
    outline: none;
}
.AdderInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 13vh;
}

.div_label_input_adder {
    min-height: 13vh;
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1.5rem;
}

.div_align_adder_input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 15vh;
    max-height: 100px;
}

.div_align_explanation_input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 75%;
    min-height: 13vh;
    padding-top: 1rem;
}

.div_field_input_adder {
    margin: 0;
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    border-radius: 0.25rem;
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: row;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.div_explanation_input {
    width: 100%;
    padding-top: 0.2rem;
}

.subtext_explanation_input {
    font-weight: normal;
    color: rgba(128, 128, 128, 0.849);
    width: 100%;
    font-size: 14px;
    margin: 0;
    padding: 2px;
}

.div_field_input_adder input {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 200ms ease-in-out;
    width: 90%;
    padding: 0.5rem;
    color: var(--eighthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
}

.div_field_input_adder:focus-within {
    padding: 0;
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

.div_field_input_adder input::placeholder {
    color: var(--eighthcolor);
}

.div_field_input_adder button {
    width: 10%;
    height: 36px;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    outline: none;
    border: none;
    align-self: center;
}

.div_field_input_adder button:active {
    outline: none;
    border: none;
}

.div_field_input_adder button:focus {
    outline: none;
    border: none;
}

.div_align_activities {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 75%;
    margin-right: 2.3%;
    align-self: flex-end;
}

.div_activities_edit_adder_input {
    width: 100%;
}

.div_act_name_list {
    background-color: #18A689;
    display: inline-block;
    flex-direction: row;
    justify-content: center;
    border-radius: 5px;
    transition: all 300ms ease-in-out;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    height: 1.7rem;
}

.div_align_div_activity {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0.3rem;
}

.div_align_component_activity {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.1rem;
}

.div_act_name_list:hover {
    background-color: #0cddb3;
}

.text_act_edit {
    font-size: 16px;
    color: white;
    align-self: center;
    justify-self: center;
    text-align: left;
}

.remove_icon_edit {
    width: 22px;
    cursor: pointer;
    align-self: center;
    justify-self: center;
}
.ImageInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30vh;
}

.div_label_input_image {
    min-height: 13vh;
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1.5rem;
}

.div_align_input_image {
    width: 100%;
    min-height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 0.2rem;
    padding-top: 0.2rem;
}

.div_align_image_input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: 20vh;
    max-height: 270px;
}

.div_align_input_image button {
    height: 80%;
    max-height: 3rem;
}

.div_field_input_image {
    margin: 0;
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    border-radius: 0.25rem;
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 25vh;
}

.div_edit_list_images {
    background-color: transparent;
    width: 100%;
    height: 25vh;
}

.div_message_noimage_input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
}

.div_message_noimage_input h1 {
    font-size: 20px;
}
.LocalImage {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 0.25rem;
    padding: 0;
}

.icon_trash_img {
    background-color: var(--ninethcolor);
    border-radius: 6px;
    width: 25px;
    margin: 3px auto;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
}

.icon_trash_img:hover {
    background-color: var(--thirteenthcolor);
}

.icon_trash_img:active {
    background-color:var(--ninethcolor);
}

.img_edit_inventory_admin {
    width: auto;
    margin: 0 auto;
    object-fit: fill;
    height: 100%;
}
.TextInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 13vh;
    max-height: 80px;
}

.div_label_input_text {
    width: 18%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
}

.div_align_img_ranking_points {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 30px;

}

.div_align_img_ranking_points span {
    visibility: hidden;
    opacity: 0;
    width: 150px;
    background-color: #ced3e7;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1000;
    top: -50px;
    right: -75px;

    /* popup delay transition */
    transition: opacity 300ms ease-in-out;
    transition-delay: 1s;
    transition-property: opacity;
}

.checkbox_ranking_type {
    top: -70px !important;
}

.radio_ranking_type {
    top: -30px !important; 
}

.div_align_img_ranking_points img { 
    width:  30px;
}
  
.div_align_img_ranking_points span::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #ced3e7 transparent transparent transparent;
}
  
.div_align_img_ranking_points:hover span {
    visibility: visible;
    opacity: 1;
}

.label_text_input {
    width: 100%;
    text-align: right;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    padding: 0;
    margin: 0;
}

.div_out_input_range {
    width: 75%;
    align-self: center;
}

.div_out_input_range input {
    margin: 0;
    padding: 0.5rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 100%;
    border-radius: 0.25rem;
    font-size: 0.9rem;
}

.div_out_input_range input::placeholder {
    color: var(--eighthcolor);
}

.div_out_input_range input:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

.error_message_text_input {
    color: red;
}
.SelectInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 13vh;
    max-height: 80px;
}

.div_select_input_select {
    width: 75%;
    align-self: center;
}

.div_select_input_select select {
    margin: 0;
    padding: 0.5rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 100%;
    border-radius: 0.25rem;
    font-size: 0.9rem;
}

.div_select_input_select select:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}
.DateInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 13vh;
    max-height: 80px;
}

.div_label_input_date {
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
}

.div_out_input_date {
    width: 75%;
    align-self: center;
}

.div_out_input_date input {
    margin: 0;
    padding: 0.5rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 100%;
    border-radius: 0.25rem;
    font-size: 0.9rem;
}

.div_out_input_date input::placeholder {
    color: var(--eighthcolor);
}

.div_out_input_date input:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}
.FileInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 13vh;
    max-height: 80px;
}

.div_align_file_input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;
}

.div_label_input_file {
    height: 13vh;
    max-height: 80px;
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1.5rem;
}

.div_field_input_file_type {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--eleventhcolor);
    border-radius: 0.25rem;
}

.div_align_input_file {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.div_align_image_input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: 20vh;
    max-height: 290px;
}

.div_align_input_file button {
    height: 100%;
    align-self: center;
    justify-self:center;
}

.div_field_input_file {
    margin: 0;
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    border-radius: 0.25rem;
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 25vh;
}

.div_align_field_file {
    padding: 0 !important;
    padding-top: 0;
    height: 10vh !important;
    max-height: 20px !important;
    background-color: var(--eleventhcolor);
}

.input_namefile_fileinput {
    height: 100% !important;
    width: 90%;
    background-color: transparent;
    padding-top: 0;
    margin: 0;
    padding-left: 1rem;
    border: none;
}

.div_explanation_input .error-message-form-cae {
    margin-top: 0 !important;
    text-align: left;
}
.div_align_single_image {
    height: 100%;
    width: auto;
    float: left;
    justify-self: flex-start;
    align-self: flex-start;
}
.SelectMultipleRender {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: 20vh;
}

.div_align_multiple_select {
    height: 30vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.div_selects_neigh_align {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}

.div_selects_neigh_align select{
    height: 100%;
    padding: 0.4rem;
    width: 43%;
    border-radius: 0.25rem;
}

.div_btns_set_neighborhood {
    height: 100px;
    width: 150px;
    margin: auto auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.btn_handle_neighborhood{
    border: none;
    background-color: var(--ninethcolor);
    width: 60%;
    height: 45%;
    max-height: 50px;
    margin: 0 auto;
    box-shadow: 0 7px rgba(0, 0, 0, 0.4);
    transition: transform 30ms ease-in-out, box-shadow 30ms ease-in-out, background-color 200ms ease-in-out;
    outline: none;
    border-radius: 0.25rem;
}

.btn_handle_neighborhood:hover{
    background-color: var(--fourteenthcolor);
}

.btn_handle_neighborhood:active{
    transform: translateY(3px);
    box-shadow: 0 4px rgba(0, 0, 0, 0.6);
    outline: none;
}

.btn_handle_neighborhood:focus{
    outline: none;
}
.RangeInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 13vh;
    max-height: 80px;
}

.div_label_input_range {
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
}

.div_out_input_text {
    width: 100%;
    align-self: center;
}

.div_out_input_text input {
    margin: 0;
    padding: 0.5rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 100%;
    border-radius: 0.25rem;
    font-size: 0.9rem;
}

.div_out_input_text input::placeholder {
    color: var(--eighthcolor);
}

.div_out_input_text input:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

input[type=range]{
    -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: var(--ninethcolor);
    margin-top: -4px;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
}

/** FF*/
input[type="range"]::-moz-range-progress {
    background-color: #43e5f7; 
}
input[type="range"]::-moz-range-track {  
    background-color: #9a905d;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: #43e5f7; 
}
input[type="range"]::-ms-fill-upper {  
    background-color: #9a905d;
}
.div_multiple_select {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.div_align_multiple_select_input {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.div_align_multiple_select_input select {
    margin: 0;
    padding: 0.5rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 75%;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-self: center;
}

.div_align_multiple_select_input, .div_algin_multiple_select_form {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
}
.add_duration_btn {
  border: none;
  background-color: var(--ninethcolor);
  width: 30%;
  height: 45%;
  max-height: 50px;
  margin-left: 120px;
  box-shadow: 0 5px rgba(0, 0, 0, 0.4);
  transition: transform 30ms ease-in-out, box-shadow 30ms ease-in-out, background-color 200ms ease-in-out;
  outline: none;
  border-radius: 0.25rem;
  margin-bottom: 40px;
  font-size: 12px;
  color:antiquewhite;
}

.add_duration_btn:hover{
  background-color: var(--fourteenthcolor);
}

.add_duration_btn:active{
  transform: translateY(3px);
  box-shadow: 0 4px rgba(0, 0, 0, 0.6);
  outline: none;
}

.add_duration_btn:focus{
  outline: none;
}

.div_align_cabecalho {
  width: 100%;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.alignPonto {
  align-self: flex-start; /* Align to the start of the flex container */
}

.alignDuracao {
  position: absolute;
  left: 55%; 
}

.div_align_input_duration {
  display: flex;
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid gray;
}

.align_title_input {
  font-size: 16px;
  align-self: start;
}

.align_input {
  position: absolute;
  left: 55%;
}

.remove_duration_btn {
  position: absolute;
  left: 75%;
  border: none;
  background-color: var(--ninethcolor);
  width: 12%;
  height: 3.5%;
  max-height: 50px;
  box-shadow: 0 2px rgba(0, 0, 0, 0.4);
  transition: transform 30ms ease-in-out, box-shadow 30ms ease-in-out, background-color 200ms ease-in-out;
  outline: none;
  border-radius: 0.25rem;
  font-size: 12px;
  color:antiquewhite;
  
}
.inputFilters {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: 20vh;
}

.div_align_multiple_select_do_nelli{
    width: 100%;
    padding-top: 10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    
}

.div_align_explanation_input_do_nelli{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 75%;
    min-height: 13vh;
    padding-top: 1rem;
}


.filter-dropdown {
    display: flex;
    flex-direction: row;
    justify-content:left;
    height: 4vh;
    border-radius: 0.25rem;
}

.filter-dropdown select {
    font-size: 0.9rem;
    color: var(--eighthcolor);
}

.select_inventory_do_nelli{
    font-weight: lighter;
    font-size: 13px;
    height: 25vh;
    width: 75%;
    margin: 0;
    padding: 6px;
    color: rgba(128, 128, 128, 0.849);
    border: 0.5px solid #e8e9ea;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    overflow: auto;
    border-radius: 0.25rem;
}

.div_selects_neigh_align select{
    height: 100%;
    padding: 0.4rem;
    width: 43%;
    border-radius: 0.25rem;
}

.div_explanation_input {
    height: 5vh;
    width: 100%;
    margin: auto auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    grid-gap: 1rem;
    gap: 1rem;
}

.btn_handle_neighborhood{
    border: none;
    background-color: var(--ninethcolor);
    width: 60%;
    height: 45%;
    max-height: 50px;
    margin: 0 auto;
    box-shadow: 0 7px rgba(0, 0, 0, 0.4);
    transition: transform 30ms ease-in-out, box-shadow 30ms ease-in-out, background-color 200ms ease-in-out;
    outline: none;
    border-radius: 0.25rem;
}

.btn_handle_neighborhood:hover{
    background-color: var(--fourteenthcolor);
}

.btn_handle_neighborhood:active{
    transform: translateY(3px);
    box-shadow: 0 4px rgba(0, 0, 0, 0.6);
    outline: none;
}

.btn_handle_neighborhood:focus{
    outline: none;
}
.ExampleInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.div_label_input_example {
    justify-content: flex-start;
    padding-top: 1rem;
}

.div_out_input_example {
    width: 100%;
    background-color: var(--secondcolor);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.img_example_input {
    width: 25%;
    margin: 0 auto;
}
.OptionEditorInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.label_editor_input {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1rem;
}

.div_out_input_editor {
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0;
    padding: 0.5rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 100%;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    background-color: white;
}

.div_align_options_editor {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.label_option_question {
    width: 14%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_out_input_editor input {
    background-color: var(--secondcolor);
    border-radius: 0.25rem;
    border: none;
    width: 80%;
    outline: none;
    padding-left: 1rem;
}

.div_out_input_editor input:focus {
    outline: none;
}

.div_out_input_editor input::placeholder {
    color: var(--eighthcolor);
}

.div_out_input_editor:focus-within {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

.btn_delete_option_editor {
    width: 4%;
    border-radius: 0.25rem;
    border: none;
    outline: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
}

.btn_delete_option_editor:hover {
    background-color: var(--fourteenthcolor);
}

.btn_delete_option_editor:active, .btn_delete_option_editor:focus {
    outline: none;
}

.btn_delete_option_editor:disabled {
    background-color: #c4c4c4;
}

.img_delete_option_editor {
    width: 100%;
    margin: 0 auto;
}

.div_add_new_option {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.div_btn_add_option {
    width: 38px !important;
    height: 38px !important;
    border-radius: 50% !important;
}

.img_add_option {
    width: 90%;
    margin: 0 auto;
}
.GPXFileInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 13vh;
  max-height: 180px;
}

.btn_remove_file_gpx {
  background-color: #2C2C2C;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.div_align_file_input {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 100%;
}

.div_label_input_file {
  height: 13vh;
  max-height: 80px;
  width: 18%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 1.5rem;
}

.div_field_input_file_type {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 40px;
  background-color: transparent;
  border-radius: 0.25rem;
}

.div_align_input_file {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.div_space_input_gpx_file {
  margin: 0;
  padding: 0.5rem;
  color: var(--eighthcolor);
  border: 0.5px solid var(--twenthcolor);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  transition: all 200ms ease-in-out;
  outline: none;
  width: 100%;
  height: 60px;
  border-radius: 0.25rem;
  font-size: 0.9rem;
}

.div_align_image_input {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-height: 20vh;
  max-height: 290px;
}

.div_align_input_file button {
  height: 100%;
  align-self: center;
  justify-self:center;
}

.div_field_input_file {
  margin: 0;
  border: 0.5px solid var(--twenthcolor);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  transition: all 200ms ease-in-out;
  border-radius: 0.25rem;
  display: flex;
  width: 100%;
  align-self: center;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 25vh;
}

.div_align_field_file {
  padding: 0 !important;
  padding-top: 0;
  height: 10vh !important;
  max-height: 20px !important;
  background-color: var(--eleventhcolor);
}

.input_namefile_fileinput {
  height: 100% !important;
  width: 90%;
  background-color: transparent;
  padding-top: 0;
  margin: 0;
  padding-left: 1rem;
  border: none;
}

.div_explanation_input .error-message-form-cae {
  margin-top: 0 !important;
  text-align: left;
}
.CityPickerInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.PlaceID {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.32);
    z-index: 100;
    top: 0;
    left: 0;
}

.inner_popup_placeid {
    width: 600px;
    height: 650px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    position: relative;
    grid-gap: 10px;
    gap: 10px;
    padding: 30px;
}

.header_placeid {
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    text-align: left;
    position: relative;
    padding: 25px;
}

.title_header_placeid {
    font-size: 22px;
    font-weight: 500;
    font-family: 'Lato';
    text-align: center;
}

.subtitle_header_placeid {
    font-size: 16px;
    font-weight: 200;
    font-family: 'Lato';
    color: var(--sixthcolor);
    margin: 0 auto;
}


.div_field_placeid {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 25px;
    height: 100px;
    position: relative;
}

.div_field_placeid label {
    position: absolute;
    left: 50px;
    top: 15px;
    background-color: white;
    z-index: 3;
    font-size: 14px;
    width: 120px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #172B4D;
}

.input_place_id {
    width: 100%;
    border: 2px solid #172B4D;
    border-radius: 10px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: transparent;
    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 10px;
    padding-left: 15px;
}

.map_place_id {
    width: calc(100% - 50px);
    height: 400px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.div_buttons_placeid {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    grid-gap: 10px;
    gap: 10px;
    padding-right: 25px;
    align-items: center;
}

.div_buttons_placeid button {
    width: 110px;
    height: 40px;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    outline: none;
}

.btn_confirm_placeid {
    background-color: #172B4D;
    color: white;
    border: none;
}

.btn_cancel_placeid {
    background-color: #EBECF0;
    border: none;
}

.btn_confirm_placeid:hover {
    background-color: #111e36;
}

.btn_cancel_placeid:hover {
    background-color: #c5c7ce;
}

.btn_cancel_placeid:active, .btn_confirm_placeid:active {
    outline: none;
}

.btn_confirm_placeid:disabled {
    background-color: #0e182c;
}
.NoResultsComponent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 76vh;
    max-height: 76vh;
    text-align: center;
}

.img_not_found {
    width: 10%;
}

.title_not_found {
    color: var(--sixthcolor);
    font-size: 1.5rem;
    font-weight: 700;
}

.text_not_found {
    color: var(--sixthcolor);
}
.btn_goback_review_pendency {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 0.25rem;
    background-color: var(--ninethcolor);
    border: none;
    border-radius: 0.25rem;
    outline: none;
    transition: all 200ms ease-in-out;
    height: 2.5rem;
    margin: 15px;
}

.btn_goback_review_pendency span {
    color: white;
    align-self: center;
}

.div_info_pendency{
    display: flex;
}

.div_docs_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.div_info_doc {
    background-color: var(--firstcolor);
    border: 1px solid var(--secondcolor);
    width: 100%;
    padding: 1rem;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
    margin: 10px;
    font-family: 'Lato', sans-serif;
    border-radius: 0.5rem;
}

.div_title_doc{
    justify-content: center;
    display: flex;
    font-size: 1.5rem;
    padding-bottom: 10px;
    font-family: 'poppins', sans-serif;
    font-weight: bolder;
}

.div_align_review_pendency {
    width: 98.2%;
    margin: 0 auto;
    background-color: white;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
}

.btn_edit_pendency {
    width: 160px !important;
    height: 40px !important;
}
.FieldReview {
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;    
    border: 1.5px solid var(--sixthcolor);
    border-radius: 0.5rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    margin-bottom: 1rem;
}

.new_field_review {
    background-color: var(--sixthcolor);
}

.title_field_review {
    width: 20%;
    align-self: center;
    font-weight: 900;
    text-align: right;
    font-size: 14px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.input_field_review {
    width: 80%;
    height: 60%;
    border: none;
    background-color: transparent;
    outline: none;
    padding-left: 1rem;
    font-size: 14px;
    resize: none;
    align-self: center;
    overflow: auto;
}

.input_field_review::-webkit-scrollbar {
    background: transparent;
    height: 100%;
    width: 5px;
}

.input_field_review::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.849);
    border-radius: 40px;    
    height: 20%;
}
.EditInventory {
    min-height: 80vh;
}

.form_edit_admin {
    margin-top: 1rem;
    border-top: 5px solid var(--eleventhcolor);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--firstcolor);
}

.div_titles_edit_inventory h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 32px;
    padding: 4px;
    margin: 0;
}

.div_titles_edit_inventory h4 {
    font-size: 16px;
    padding: 6px;
}

/* All divs components*/
.div_edit_textarea_type, .div_edit_text_type, .div_edit_elements_type, .div_edit_select_type, .div_edit_radio_type, .div_edit_checkbox_type, .div_edit_image_type {
    margin: 0;
    padding: 5px;
}

.div_add_act {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: white;
}

.div_edit_radio_type, .div_edit_checkbox_type, .div_edit_checkbox_column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.div_edit_checkbox_type {
    width: 50%;
}

.div_edit_checkbox_row {
    padding: 5px;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.div_edit_checkbox_row_segments {
    grid-template-columns: 33.3% 33.3% 33.3%;
}

.div_edit_checkbox_row_comtur {
    grid-template-columns: 100%;
}

.div_edit_checkbox_row div {
    width: 100%;
}


.div_btn_edit_inventory {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 45%;
    height: 10vh;
}

.div_edit_image_type {
    height: 15vh;
}

.div_edit_map_type {
    height: 70vh;
    width: 50%;
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_input_geolocation {
    width: 45%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
    border: 0.5px solid #e8e9ea;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.txt_title_inventory, .subtext_inventory, .div_label_inventory label, .div_edit_checkbox_type label {
    font-weight: bold;
    color: #767676;
    font-size: 14px;
    margin: 0;
    padding: 2px;
}

.subtext_inventory {
    font-weight: normal;
    color: rgba(128, 128, 128, 0.849);
    width: 45%;
}

.input_text_inventory, .textarea_inventory, .select_inventory, .input_add_act {
    font-weight: lighter;
    font-size: 13px;
    width: 45%;
    margin: 0;
    padding: 6px;
    color: rgba(128, 128, 128, 0.849);
    border: 0.5px solid #e8e9ea;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
}

.input_geolocation {
    font-weight: lighter;
    font-size: 13px;
    color: rgba(128, 128, 128, 0.849);
    outline: none;
    background: none;
    border: none;
    width: 92%;
    padding: 6px;
}

.div_input_geolocation:focus-within {
    border: 1.5px solid #18A689;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.textarea_inventory:focus, .input_text_inventory:focus, .select_inventory:focus, .input_add_act:focus {
    border: 1.5px solid #18A689;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.input_add_act {
    width: 80%;
}

.textarea_inventory {
    height: 10vh;
    border: 0.5px solid rgba(128, 128, 128, 0.849);
}

.select_inventory {
    font-size: 15px;
}

.radio_input_inventory:checked, .radio_input_inventory.with-gap:checked {
    background-color: #767676;
}

.btn_edit_inventory, .btn_image_inventory, .btn_add_act, .btn_open_map {
    color: white;
    background-color: #18A689;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    width: 17%;
    height: 60%;
    margin: 5px;
    transition: all 300ms ease-in-out;
}

.btn_add_act {
    width: 20%;
    height: 32px;
    border-radius: 0px;
    align-self: center;
    margin: 0;
    padding: 0;
}

.btn_open_map {
    width: 8%;
    height: 2rem;
    margin: 0;
    padding: 0;
    transition: all 200ms ease-in-out;
}


.btn_image_inventory {
    width: 12%;
    height: 20%;
    transition: all 300ms ease-in-out;
}

.btn_image_inventory:focus {
    outline: none;
}

.btn_image_editCity {
    height: 2.4rem;
    transition: all 300ms ease-in-out;
}

.input_file_inventory {
    display: none;
}

.div_edit_image_type {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 45vh;
}

.div_image_editCity {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.btn_edit_inventory:hover,  .btn_image_inventory:hover, .btn_add_act:hover, .btn_open_map:hover{
    background-color: #0cddb3;
}

.div_activities_edit {
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    width: 45.5%;
    height: 15vh;
}

.div_selects_neigh_align select::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 3px;
}

.div_selects_neigh_align select::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
    border-radius: 5px;
}



.img_arrow_region {
    background: none;
    width: 100%;
}

.img_map_btn {
    width: 98%;
    height: 100%;
}

.div_align_multiple_select {
    width: 100%;
    padding: 0;
}

.div_align_multiple_select select {
    width: 100%;
}

.div_values_multiple_select select{
    height: 100%;
}

.divisor_component {
    border-top: 1px dashed var(--eleventhcolor);
    width: 95%;
    text-align: center;
}
.TitleReviewPendency {
    width: 98.3%;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
}
.RodapeAdmin {
    width: 100% + 1rem;
    height: 7vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
    bottom: 0;
    left: 0;
}

.txt_rodape_admin {
    text-align: left;
    color: gray;
    margin: 0;
    padding-left: 0.5rem;
}
.label_check_pendencies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 40%;
    margin-top: 0.5rem;
  }
  
  .label_check_pendencies input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark_pendy {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #eee;
    border: 2px solid #eee;
    transition: all 100ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .label_check_pendencies:hover input ~ .checkmark_pendy {
    background-color: #ccc;
    border-color: #2d8f7b;
  }
  
  .label_check_pendencies input:checked ~ .checkmark_pendy {
    background-color: #2d8f7b;
    border-color: #2d8f7b;
  }
  
  .checkmark_pendy:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .label_check_pendencies input:checked ~ .checkmark_pendy:after {
    display: block;
  }
  
  .label_check_pendencies .checkmark_pendy:after {
    align-self: center;
    justify-self: center;
    margin-top: -15%;
    width: 35%;
    height: 60%;
    border: 1px solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
.body_header_admin {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 60px;
}

.div_btn_sidebar {
    background-color: var(--firstcolor);
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 60px;
    width: 250px;
}

.btn_header_adm {
    background-color: #18A689;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 42px;
    width: 3.3vw;
    min-height: 40px;
    height: 3vw;
    align-self: center;
    border-radius: 4px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.btn_header_adm:hover {
    background-color: #0cddb3;
}

.strip_btn_header_adm {
    background-color: white;
    min-width: 10px;
    width: 1.2vw;
    min-height: 2px;
    height: 0.2vw;
    margin: 1px auto;
}

.div_title_header {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
}

@media only screen and (min-width:3840px){
    .div_title_header{
        height: 100%;
        width: 200%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 20px;
        padding-left: 5px;
    }
}

@media only screen and (min-width:3840px){
    .strip_btn_header_adm {
        background-color: white;
        width: 0.6vw;
        height: 0.1vw;
        margin: 1px auto;
    }
}

@media only screen and (min-width:3840px){
    .btn_header_adm {
        background-color: #18A689;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60px;
        height: 50px;
        align-self: center;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 5px;
    }
}

.div_logout_adm {
    height: 100%;
    width: 23vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn_logout_adm {
    width: 70%;
    background-color: transparent;
    border: none;
    outline: none;
}

.btn_logout_adm:active {
    outline: none;
    border: none;
}

.div_select_city_header {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_select_city_header label, .div_select_city_header select {
    height: 20%;
    justify-self: center;
    align-self: center;
    color:  #b8b7b4;
    font-size: 16px;
}

.div_select_city_header select {
    width: 60%;
    height: 50%;
    color: black;
}

.icon_logout_header {
    width: 20%;
}

.SidebarUserCity {
    font-weight: 600;
}

.SidebarUserName {
    color: #8095a8;
}
.div_cardsdashboard {
    display: grid;
    width: 100%;
    grid-template-columns: 33.1% 33.1% 33.1%;
}

.div_cardInfo_dashboard {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_cards_places_info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_align_cards_sunburst {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_cards_operator_segments_info {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.card_container_dash {
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 600px) {
    .div_cardsdashboard, .div_cards_operator_segments_info, .div_cards_places_info, .div_cardInfo_dashboard {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
}
/* General CSS */
.div_top_header_dash {
    width: 100%;
    height: 33%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #f5f5f5;
}

.title_cardInfo {
    font-size: 15px;
    align-self: center;
    font-weight: 1000;
    margin: 0;
    padding-left: 0.5rem;
}

.div_footer_dash {
    width: 100%;
    height: 67%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_footer_dash div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 0.7rem;
}

.h1_cardInfo {
    font-size: 28px;
    font-weight: 500;
    margin: 0;
}

.txt_cardInfo {
    color: #1ab394;
    margin: 0;
}

.btn_card_info, .btn_agency_info {
    background-color: #1ab394;
    width: 60px;
    height: 22px;
    border-radius: 4px;
    border: none;
    color: white;
    align-self: center;
    margin-right: 0.5rem;
}

/* AgencyInfo CSS */

.AgencyInfo {
    width: 31%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-top: 5px solid #e7eaec;
}

.btn_agency_info {
    background-color: #1c84c6;
}

/* EventsCityInfo CSS */
.EventsCityInfo {
    width: 40%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-top: 5px solid #e7eaec;
}

/* EmployeeInfo CSS */
.EmployeeInfo {
    width: 26%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-top: 5px solid #e7eaec;
}

/* AtractiveOperator */
.AtractiveOperator {
    width: 38%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    margin: 10px;
    border-top: 5px solid #e7eaec;
}

@media only screen and (max-width: 600px) {
    .AgencyInfo, .EventsCityInfo, .EmployeeInfo, .AtractiveOperator {
        width: 80%;
    }

    .AtractiveOperator {
        margin: 0;
    }

    .h1_cardInfo, .txt_cardInfo {
        font-size: 26px;
    }
    .txt_cardInfo {
        font-size: 11px;
    }

    .btn_agency_info, .btn_card_info {
        font-size: 10px;
    }

    .title_cardInfo { 
        font-size: 12px;
    }
}

@media only screen and (min-width: 1700px) {
    .h1_cardInfo, .txt_cardInfo {
        font-size: 2rem;
        font-weight: 700;
    }

    .txt_cardInfo {
        font-size: 1.1rem;
    }

    .btn_agency_info, .btn_card_info {
        font-size: 1.2rem;
    }

    .title_cardInfo { 
        font-size: 1.4rem;
    }

    .btn_card_info, .btn_agency_info {
        width: 90px;
        height: 32px;
        font-size: 1.2rem;
    }
}
.CardHeatmap {
    background-color: white;
    width: 55vw;
    height: 109vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8px;
    border-top: 5px solid #e7eaec;
}

.map_container {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    padding: 50px !important;
    flex-direction: column !important;
    justify-content: center !important;
    padding-top: 1rem !important;
    left: 0;
    top: 0;
}

.div_map_cardmap {
    width: 100%;
    height: 77%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    position: relative;
}

.div_align_stats_map {
    height: 13%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 45px;
    padding-right: 45px;
}

.div_map_buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding: 1rem;
    width: 35%;
}

.div_btn_align_map {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.div_span_align_map {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    background-color: var(--eleventhcolor);
    max-width: 200px;
    margin: 0 auto;
}

.btn_setdate_map {
    border: none;
    box-shadow: gray 15px;
    width: 80px;
    height: 25px;
    font-size: 10px;
    font-weight: 700;
    border-radius: 3px;
    transition: all 300ms ease-in-out;
}

.btn_setdate_map:hover {
    background-color: rgb(220, 220, 220);
}

.div_btn_align_map p {
    font-size: 10px;
    margin: 0;
    padding: 0;
    align-self: center;
}

.div_span_align_map span {
    font-size: 14px;
    margin: 0;
    padding: 0;
    align-self: center;
}

.div_align_info_map {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
}

.div_align_info_map p {
    font-size: 16px;
    margin: 0;
    padding: 0;
    align-self: center;
}

@media only screen and (max-width: 600px) {
    .CardHeatmap {
        width: 80%;
        height: 120vh;
        margin: 0;
    }

    .div_map_cardmap { 
        height: 70%;
    }

    .map_container {
        width: 80% !important;
    }

    .div_map_buttons {
        width: 100%;
    }
}

@media only screen and (min-width: 1700px) {
    .btn_setdate_map {
        width: 120px;
        height: 40px;
        font-size: 1rem;
    }

    .div_btn_align_map p {
        font-size: 14px;
        margin: 0;
        padding: 0;
        align-self: center;
    }
}
.div_back_datepopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.div_inner_datepopup {
    position: relative;
    padding: 32px;
    width: 360px;
    height: 430px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 10px;
    padding: 25px;
    grid-gap: 20px;
    gap: 20px;
}

.div_btns_datepopup {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_btns_datepopup button {
    align-self: center;
    font-size: 14px;
    font-family: Lato;
    width: 45%;
    height: 80%;
    border-radius: 5px;
    transition: all 200ms ease-in;
    color: white;
    background-color: #172B4D;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.div_btns_datepopup button:hover {
    background-color: #111C30;
}

.div_btns_datepopup button:first-child {
    background-color: white;
    color: black;
    border: 1px solid #EBECF0 !important;
}

.div_btns_datepopup button:first-child:hover {
    background-color: #EBECF0;
}

.calendar_component {
    height: 266px !important;
    width: 100% !important;
    font-size: 14px !important;
    margin: 0 auto;
}
.CardDashboard {
    width: 98%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    margin: 8px;
    border-top: 5px solid var(--eleventhcolor);
    transition: all 300ms ease-in-out;
}

.div_headear_carddashboard {
    width: 100%;
    height: 10%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #f5f5f5;
}

.div_headear_carddashboard h1 {
    align-self: center;
}

.btn_close_carddashboard {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
}

.btn_close_carddashboard:focus {
    border: none;
    outline: none;
}

.arrow_down_carddashboard {
    width: 100%;
}

.div_headear_carddashboard h1 {
    font-size: 15px;
    font-weight: 800;
    margin: 8px;
}

.div_body_carddashboard {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.div_bodyleft_carddashboard {
    width: 27%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.div_info_left_carddashboard {
    width: 100%;
    height: 22%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
}

.div_info_left_carddashboard h1 {
    font-size: 22px;
    font-weight: 500;
}

.div_info_left_carddashboard p{
    color: #1ab394;
    font-weight: 500;
}

.div_bodyright_carddashboard {
    width: 73%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.div_info_carddashboard {
    width: 100%;
    height: 18%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_top_info_carddashboard {
    width: 100%;
    height: 77%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.div_left_info_carddashboard {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.div_right_info_carddashboard {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.h1_info_carddashboard {
    font-size: 19px;
    font-weight: 800;
    margin: 0;
    padding: 0;
}

.h3_info_carddashboard {
    font-size: 15px;
    font-weight: 900;
}

.div_porcent_outbar {
    background-color: #f5f5f5;
    width: 90%;
    border-radius: 10px;
    overflow: hidden;
}

.div_porcent_innerbar {
    background-color: #1ab394;
    height: 6px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

@media only screen and (max-width: 600px) {
    .CardDashboard {
        width: 80%;
        margin: 0;
        padding-top: 0.5rem;
    }

    .div_info_left_carddashboard p{
        font-size: 10px;
    }

    .h1_info_carddashboard {
        font-size: 15px;
    }

    .div_info_left_carddashboard h1 {
        font-size: 16px;
        font-weight: 700;
    }

    .h3_info_carddashboard {
        font-size: 13px;
    }

    .div_left_info_carddashboard p {
        font-size: 12px;
    }
}

@media only screen and (min-width: 1700px) {
    .div_info_left_carddashboard p{
        font-size: 1.1rem;
    }

    .h1_info_carddashboard {
        font-size: 2rem;
    }

    .div_headear_carddashboard h1 {
        font-size: 1.4rem;
    }

    .div_info_left_carddashboard h1 {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .h3_info_carddashboard {
        font-size: 1.5rem;
    }

    .div_left_info_carddashboard p {
        font-size: 1.1rem;
    }
}
.CardInventoryAdmin {
    background-color: white;
    width: 40%;
    height: 109vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8px;
    border-top: 5px solid #e7eaec;
}

.div_body_cardinvertory {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
}

.table_cardinventory {
    height: 100%;
}

.table_cardinventory th {
    background-color: #f5f5f6;
}

.td_cardinventory, .td_cardinventory_desc, .td_value_cardinventory {
    border: 1px solid #ebebeb;
    height: 30px;
}

.td_value_cardinventory {
    width: 16%;
}

.td_cardinventory, .td_value_cardinventory {
    text-align: center;
}

.td_cardinventory_desc {
    text-align: left;
    padding-left: 0.5rem;
}

.img_cardinventory {
    width: 46px;
}

.img_situation_icon_dashboard {
    width: 25%;
    margin: 0;  
}

.td_date {
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.td_date:hover {
    opacity: 0.4;
}


@media only screen and (max-width: 600px) {
    .CardInventoryAdmin {
        width: 80%;
        height: 128vh;
        margin: 0;
        font-size: 11px;
    }

    .CardInventoryAdmin h1 {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1700px) {
    .td_cardinventory_desc {
        font-size: 1.1rem;
    }
}
.CardTableDashboard {
    background-color: white;
    width: 28%;
    height: 44vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 10px;
    border-top: 5px solid #e7eaec;
}

.div_headear_cardtable {
    width: 100%;
    height: 18%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 2px solid #f5f5f5;
    padding-left: 0.5rem;
}

.div_body_cardtable {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
}

.table_cardtable {
    width: 92%;
    height: 100%;
    margin: 0 auto;
    margin-top: 5%;
}

.title_cardtable {
    font-size: 15px;
    font-weight: 800;
    margin: 0;
}

.th_table_dashboard_column, .th_table_dashboard_semicolumn {
    background-color: #f5f5f6;
    border: 1px solid #ebebeb;
    padding-left: 0.5rem;
    height: 8px;
}

.th_table_dashboard_column {
    width: 75%;
}

.th_table_dashboard_semicolumn {
    width: 25%;
}

.table_cardtable .td_cardinventory_desc {
    width: 70%;
}

@media only screen and (max-width: 600px) {
    .CardTableDashboard {
        width: 80%;
        margin: 0;
    }

    .CardTableDashboard h1 {
        font-size: 14px;
    }

    .table_cardtable {
        font-size: 12px;
    }
}

@media only screen and (min-width: 1700px) {
    .title_cardtable {
        font-size: 1.4rem;
    }

    .table_cardtable {
        font-size: 1.1rem;
    }
}
.CardChartDashboard {
    background-color: white;
    width: 50%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8px;
    border-top: 5px solid #e7eaec;
}

.div_header_chart_dashboard {
    height: 80px;
}

.div_align_chart_dashboard {
    width: 100%;
    height: calc(100% - 80px);
    display: flex;
}

.sunburst-viz {
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sunburst-viz svg {
    width: 150% !important;
}

.sunburst-viz g {
    font-size: 20px !important;
}

.div_popup_chart {
    position: absolute;
    background-color: red;
    z-index: 1000;
}

.tooltip {
    opacity: 1 !important;
}
.div_report_admin {
    width: 95%;
    background-color: white;
    border-radius: 5px;
    box-shadow: -4px 3px 10px rgba(0, 0, 0, 0.19);
    padding-top: 2rem;
}

.div_report_admin .content_b8sgnx {
    width: 90% !important;
}

.RowPendencyCounter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.div_text_pendency_counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.div_text_pendency_counter p {
    margin-bottom: 0 !important;
}

.div_pendency_counter {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 3px;
    margin-right: 0.3rem;
    width: 3.3rem !important;
}

.div_pendency_counter p {
    color: var(--firstcolor);
    font-size: 11px;
    margin-bottom: 0 !important;
}
.RankingCities {
    height: 100%;
    width: 100%;
    background-color: var(--secondcolor);
    padding-left: 1.5%;
    padding-top: 2%;
    font-size: 1vw;
}

.div_table_score_ranking_cities {
    background-color: white;
    border-top: 2.5px solid var(--secondcolor);
    padding-top: 0.25rem;
    padding-bottom: 0;
}

.table_score_ranking_cities {
    margin-bottom: 0;
}

.th_ranking_cities {
    cursor: pointer;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
}

.th_ranking_cities:hover {
    opacity: 0.8;
}

.th_ranking_cities_descr, .td_ranking_cities_descr {
    width: 22%;
    text-align: left !important;
    padding-left: 1rem !important;
}

.th_ranking_cities_info {
    width: 12%;
}

.th_ranking_cities_more {
    width: 6%;
}

.info_table_ranking_cities {
    color:black;
    font-weight:400;
    font-size: 16px;
    text-align: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.btn_show_more_ranking_cities {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--ninethcolor);
    border: none;
    border-radius: 0.25rem;
    width: 60%;
    margin: 0 auto;
    outline: none;
    transition: all 200ms ease-in-out;
}

.btn_show_more_ranking_cities:hover {
    background-color: var(--fourteenthcolor);
}

.btn_show_more_ranking_cities:active {
    outline: none;
}

.img_btn_show_more_ranking_cities {
    width: 100%;
    margin: 0 auto
}

.div_aling_filter_index {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 4rem;
}

.div_filter_index {
    width: 8%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto;
}

.btn_index_filter {
    width: 40px;
    height: 40px;
    border-radius: 0.25rem;
    background-color: var(--ninethcolor);
    display: flex;
    border: none;
    flex-direction: column;
    justify-content: center;
    transition: all 200ms ease-in-out;
    outline: none;
}

.btn_index_filter:hover {
    background-color: var(--fourteenthcolor);
}

.btn_index_filter:active, .btn_index_filter:focus {
    outline: none;
}

.img_btn_index_filter {
    width: 100%;
    margin: 0 auto;
}
.CardScoreCalculator {
    width: 99%;
    background-color: white;
    border-top: 5px solid var(--eleventhcolor);
    padding-top: 1rem;
}

.title_card_calculator {
    font-weight: 600;
    font-size: 24px;
    padding-left: 1rem;
}

.div_handle_size_cards_score {
    width: 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: right;
    margin-right: 1rem;
    cursor: pointer;
}

.img_handle_size_score {
    width: 50%;
}

.div_align_scores_ranking {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 2px solid var(--secondcolor);
}

.div_table_score_calculator {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
    overflow: hidden;
    transition: all 300ms ease-in-out;
}

.table_score_calculator {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    margin-bottom: 3rem;
}

.sub_rows_table_score, .row_table_score_calculator {
    height: 2.5rem;
    padding: 8px;
    line-height: 1.42857143;
    border-top: 1px solid #ddd;
}

.sub_rows_table_score {
    border-top: 1px solid #ddd;
}

.title_rows_table_score {
    border-top: 1px solid white;
    color: #676a6c;
    font-weight: 100;
}

.bg_gray {
    background-color: #f9f9f9;
}

.info_table_score {
    color:black;
    font-weight:400;
    font-size: 16px;
    text-align: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.title_table_score {
    text-align: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.short_td_table_score {
    width: 4%;
}

.medium_td_table_score {
    width: 12%;
}

.score_type_table {
    width: 3%;
}

.default_td_table_score {
    width: 8%;
}

.large_td_table_score {
    width: 51%;
    text-align: left;
}

.div_pie_chart_calculator {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    margin: 0 auto;
    background-color: #d7d7d7;
}

.amount_table_score {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    align-items: center !important;
    align-self: center;
    justify-self: center;
    margin: 0 auto;
}
.CardInfoCalculator {
    width: 21%;
    margin-left: 2rem;
}

.div_card_info_calculator {
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #ffffff;
    width: 100%;
    height: 72%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_align_card_info_calculator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.img_type_score_calculator {
    width: 85%;
}
.edit_info_table_score {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin: 0 auto;
}

.icon_edit_info_table_score {
    width: 11.25px;
    align-self: center;
    cursor: pointer;
}

.aling_icons_edit_info_table_score {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    right: 10px;
}

.aling_icons_edit_info_table_score img:first-child {
    width: 15px;
}

.edit_info_table_score select, .edit_info_table_score input {
    outline: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    width: 70%;
    height: 32px;
    border-radius: 5px;
    border: 1px solid #DDDDDD;
}
.edit_info_table_score input {
    -webkit-appearance: none;
}
.CardReportCalculator {
    text-decoration: none;
    color: #000;
    background: #ffc;
    height: 55vh;
    max-height: 310px;
    width: 80%;
    padding: 1em;
    position: relative;
    list-style-type: none;
    /* Firefox */
    /* Safari+Chrome */
    /* Opera */
    box-shadow: 5px 5px 7px rgb(33 33 33 / 70%);
    transition: all 200ms ease-in-out;
    margin: 1.2rem;
}

.style_card_deleted {
    background: lightgray;
}

.cardReportLeft {
    -webkit-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
}

.cardReportRight {
    -webkit-transform: rotate(4deg);
    -o-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
}

.cardReportLeft:hover, .cardReportRight:hover {
    scale: 1.1;
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
}

.div_align_card_report {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.div_align_card_report small {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
}

.div_align_card_report h4 {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    font-family: inherit;
    margin-bottom: 10px;
}

.div_align_card_report p {
    font-size: 12px;
    margin: 0 0 10px;
}

.span_info_card_report {
    background-color: #1c84c6;
    font-family: 'Open Sans';
    color: #FFFFFF;
    font-weight: 600;
    padding: 3px 8px;
    text-shadow: none;
    border-radius: 0.25em;
    text-align: center;
    align-self: flex-end;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.div_align_card_report img {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 17px;
    cursor: pointer;
}
.RankingCalculator {
    height: 100%;
    width: 100%;
    background-color: var(--secondcolor);
    padding-left: 1.5%;
    padding-top: 2%;
    font-size: 1vw;
}

.btn_goback_ranking_cities {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 0.25rem;
    background-color: var(--ninethcolor);
    border: none;
    border-radius: 0.25rem;
    outline: none;
    transition: all 200ms ease-in-out;
    height: 2.5rem;
}

.btn_goback_ranking_cities span {
    color: white;
    justify-self: center;
    align-self: center;
    width: 70%;
}

.btn_goback_ranking_cities:hover {
    background-color: var(--fourteenthcolor);
}

.btn_goback_ranking_cities:active {
    outline: none;
}

.img_btn_goback_ranking_cities {
    width: 30%;
    justify-self: center;
    align-self: center;
}

.div_align_title_calculator {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.div_cards_pontuation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
}

.div_card_max_pontuation {
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: #1c84c6;
    color: #ffffff;
    width: 23%;
}

.div_card_curr_pontuation {
    background-color: #1ab394;
}

.div_card_setur_pontuation {
    background-image: linear-gradient(to bottom right, #FF4500	, #FFD700);
}

.div_align_card_max {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.div_img_card_max {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.div_img_card_max img {
    width: 99%;    
}

.div_info_card_max {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 70%;
    text-align: right;
}

.title_score_calculator {
    font-weight: 600;
    font-family: inherit;
}

.div_align_cards_score {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.div_align_cards_reports {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    margin-top: 1.5rem;
    width: 99%;
}

.ul_cards_reports {
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding-top: 2rem;
    margin: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

#list_cards_reports {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    transition: all 800ms ease-out;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_title_cards_reports {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 5px solid var(--eleventhcolor);
    padding: 14px 15px 7px;
    height: 48px;
    border-bottom: 2px solid var(--secondcolor);
}

.div_title_cards_reports h5 {
    display: inline-block;
    font-size: 14px;
    margin: 0 0 7px;
    padding: 0;
    text-overflow: ellipsis;
    font-weight: 600;
    color: black;
}

.div_handle_size_cards_reports {
    width: 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btn_handle_size_calculator {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: 55%;
    justify-self: flex-end;
    align-self: flex-end;
}

.btn_handle_size_calculator:focus {
    outline: none;
}

.img_handle_size_calculator {
    width: 100%;
    cursor: pointer;
}

.div_align_show_delete_cards {
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
}

.label_show_delete_cards {
    margin-left: 1rem;
    justify-self: center;
    align-self: center;
}

.label_show_delete_cards:hover input ~ .checkmark_cards_delete {
    background-color: #ccc;
    border-color: #1c84c6;
}

.label_show_delete_cards input:checked ~ .checkmark_cards_delete {
    background-color: #1c84c6;
    border-color: #1c84c6;
}

.label_text_delete_cards {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding-left: 0.5rem;
    cursor: pointer;
}

.WarningPopup {
    width: 99.3%;
    height: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 auto;
    border-radius: 0.5rem;
    padding: 0.25rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.div_icon_warning_popup {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_icon_warning_popup {
    width: 10%;
}

.div_message_warning_popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon_warning_popup {
    width: 50%;
    margin: 0 auto;
}

.div_message_warning_popup p {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 15px;
    font-family: Lato;
    margin: 0;
    padding: 0;
}
.div_align_button_form_question {
    width: 100%;
    background-color: white;
    margin-top: 2rem;
    border-top: 5px solid var(--eleventhcolor);
}

.div_align_form_question {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    overflow: hidden;
    transition: all 300ms ease-in-out;
}

.form_questions_demand {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.div_add_new_question {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 10vh;
}

.div_btn_add_question {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 130px;
    height: 40px;
    background-color: var(--ninethcolor);
    border-radius: 0.25rem;
    margin: 0 auto;
    cursor: pointer;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
}

.div_btn_add_question:hover {
    background-color: var(--fourteenthcolor);
}

.img_add_question {
    width: 20%;
    margin: 0 auto;
}

.div_btn_add_question span {
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.9rem;
    text-align: left;
}
.EditTemplates {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.div_align_body_edit_templates {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    min-height: 85vh;
}

.btn_goback_templates {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 0.25rem;
    background-color: var(--ninethcolor);
    border: none;
    border-radius: 0.25rem;
    outline: none;
    transition: all 200ms ease-in-out;
    height: 2.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.btn_goback_templates span {
    color: white;
    font-size: 14px;
    align-self: center;
    justify-self: center;
}

.header_edit_template {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.header_edit_template h1 {
    font-size: 20px;
    font-weight: 600;
}

.header_edit_template span {
    font-size: 14px;
}

.body_edit_template {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 40px;
    gap: 40px;
}

.card_template_default {
    width: 180px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    margin: 10px;
}

.card_template {
    width: 180px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 5px;
    gap: 5px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    overflow: hidden;
    margin: 10px;
}

.card_template:hover,.card_template_default:hover {
    scale: 1.02;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.div_template_doc {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: var(--thirdcolor);
}

.div_img_templateDoc {
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--fourthcolor);
}

.div_img_templateDoc img {
    height: 50%;
    align-self: center;
}

.card_template_default img {
    width: 70px;
    margin: 0 auto;
}

.card_template_default span {
    font-size: 14px;
    color: #696C6E;
    width: 70%;
    margin: 0 auto;
    font-family: Verdana,Geneva,sans-serif; 
}

.card_template span {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    font-size: 15px;
    flex-direction: column;
    justify-content: center;
    font-family: Verdana,Geneva,sans-serif;
    font-weight: 400;
}

.div_my_templates {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.table_my_templates {
    width: 100%;
}

.table_my_templates tr {
    border-bottom: 1px solid var(--sixthcolor);
}

.tr_td_my_template {
    cursor: pointer;
}

.tr_td_my_template:hover {
    background-color: var(--twenthcolor);
}

.table_my_templates td {
    padding: 10px;
    font-family: Verdana,Geneva,sans-serif; 
    font-size: 14px;
}

.table_my_templates th {
    padding: 10px;
    font-family: Verdana,Geneva,sans-serif; 
    font-size: 15px;
    font-weight: 400;
    color: var(--seventhcolor);
}


.FilePDF {
    width: 100%;
    background-color: #F6F7FC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.inner-popup-pdf {
    width: 80%;
    min-height: 90vh;
    background-color: #F6F7FC;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.div-align-btn-pdf {
    position: absolute;
    top: 20px;
    right: 8px;
    height: 100%;
    width: 200px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: right;
    grid-gap: 20px;
    gap: 20px;
    padding-right: 20px;
}

.div-btn-close-pdf, .div-btn-generate-pdf {
    width: 45px;
    height: 45px;
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 15.5px;
    gap: 15.5px;
    overflow: hidden;
    transition: all 200ms ease-in;
    background-color: #172B4D;
    border-radius: 5px;
    padding: 0;
    cursor: pointer;
    padding-left: 15.5px;
}

.div-btn-generate-pdf {
    top: 75px;
    background-color: white;
    border: 1px solid #EBECF0;
}

.div-btn-close-pdf:hover {
    width: 130px;
    background-color: #111C30;
}

.div-btn-generate-pdf:hover {
    width: 150px;
}

.img-close-pdf, .img-generate-pdf {
    width: 14px;
}

.div-btn-close-pdf span {
    color: var(--firstcolor);
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.div-btn-generate-pdf span {
    color: #172B4D;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.div-align-body-pdf {
    width: 100%;
    min-height: 90vh;
    background-color: #F6F7FC;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 50px;
    gap: 50px;
    position: relative;
}

.div-body-pdf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 50px;
    gap: 50px;
    padding: 35px;
}

.header-report-pdf {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--sixthcolor);
    grid-gap: 10px;
    gap: 10px;
    padding: 30px;
}

.header-report-pdf span {
    color: #172B4D;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.header-report-pdf span:first-child {
    font-weight: 900;
    font-size: 22px;
}

.body-report-pdf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 70px;
    gap: 70px;
}

.section-report-pdf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #F6F7FC;
}

.div-title-pdf {
    height: 50px;
    color: #42526E;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    width: 70%;
    margin: 0 auto;
    text-align: left;
}

.div-table-pdf {
    width: 70%;
    margin: 0 auto;
    border-collapse: collapse;
    background-color: transparent;
}

.header-table-pdf tr {
    border-bottom: 2px solid #172B4D;
    background-color: transparent;
    height: 55px;
    padding: 15px;
}

.header-table-pdf tr th {
    font-family: 'Roboto', sans-serif;
    color: #172B4D;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.header-table-pdf tr th:first-child {
    text-align: left;
    padding-left: 40px;
}

.header-table-pdf tr th:last-child {
    text-align: center;
}

.row-table-pdf {
    background-color: var(--firstcolor);
    height: 30px;
    transition: all 200ms ease-in-out;
    border-bottom: 1px solid #EBECF0;
}

.row-table-pdf:hover { 
    background-color: #EBECF0;
}

.row-table-pdf td { 
    color: #42526E;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 15px;
}

.row-table-pdf td:first-child{
    text-align: left;
    padding-left: 40px;
    width: 70%;
}

.row-table-pdf td:last-child{
    text-align: center;
}

.div-align-chart-pdf {
    width: 70%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
    grid-gap: 30px;
    gap: 30px;
}

@media print {
    .apexcharts-legend-marker {
      content: "\25A0";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      line-height: 12px;
      font-size: 24px;
      border-radius: 50px !important;
      align-self: center;
      justify-self: center;
      z-index: 10;
    }

    .apexcharts-legend-text {
        z-index: 5;
    }
  }
.Experiences {
  background-color: #F6F7FC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 45px;
}

.body-experiences {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 49px;
  gap: 49px;
}

.div-header-experiences {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.div-header-experiences h1 {
  color: #172B4D;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div-create-experiences {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 14px;
  gap: 14px;
}

.add-new-experience {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #172B4D;
  /* width: 143.311px; */
  width: 180px;
  height: 50px;
  flex-shrink: 0;
  color: #FFF;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 9px;
  gap: 9px;
  align-items: center;
  transition: all 200ms ease-in-out;
}

.add-new-experience:hover {
  background: #111C30;
}

.add-new-experience:active, .add-new-experience:focus {
  outline: none;
}

.div-search-experiences {
  border: 2px solid #EEE;
  background: #FFF;
  width: 383px;
  height: 50px;
  flex-shrink: 0;
  display: flex;
  flex: row 1;
  justify-content: space-evenly;
}

.div-search-experiences input {
  width: calc(100% - 18px - 18px - 22px);
  border: none;
  color: #C5C5C5;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  padding-left: 15px;
}

.div-search-experiences input::placeholder {
  color: #C5C5C5;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div-search-experiences img {
  width: 22px;
}

.div-row-header-list {
  display: flex;
  flex-direction: row;
  height: 85px;
  justify-content: space-between;
  background-color: transparent;
  border-bottom: 2px solid #172B4D;
  align-items: center;
}

.div-row-body-list {
  width: 100%;
  height: 78px;
  flex-shrink: 0;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 200ms ease-in-out;
}

.div-row-body-list:hover {
  background: #EBECF0;
}

.div-row-header-list div {
  color: #172B4D;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.div-row-body-list div {
  color: #42526E;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section-short-list {
  width: 10%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-medium-list {
  width: 15%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-long-list {
  width: 30%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-option-list {
  width: 8%;
  min-width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.section-option-list button {
  border-radius: 5px;
  background: #172B4D;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border: none;
  transition: all 200ms ease-in-out;
}

.section-option-list button:hover {
  background: #111C30;
}

.section-option-list button:active, .section-option-list button:focus {
  outline: none;
}

.popup-options-list {
  width: 143px;
  flex-shrink: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  right: calc(50% - 20px);
  z-index: 10;
  top: -30px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: none;
}

.popup-options-list-larger {
  top: -78px !important;
}

.more-options-list {
  top: -75px;
}

.popup-options-list div {
  width: 100%;
  display: flex;
  height: 48px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}

.popup-options-list div:hover {
  background: #EEE;
}

.popup-options-list div span {
  color: #42526E;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup-options-list div img {
  width: 10px;
  height: 11.25px;
  flex-shrink: 0;
}

.div-calculator-experience {
  align-self: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #F6F7FC;
}

.red-classification-experience {
  background-color: rgba(239, 69, 69, 0.59);
}

.yellow-classification-experience {
  background: rgba(250, 255, 0, 0.69);
}

.green-classification-experience {
  background: rgba(57, 251, 100, 0.62);
}


.edit-container {
  max-width: 1183px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  grid-gap: 20px;
  gap: 20px;
}

.div-path-edit {
  color: #42526E;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header-edit {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
}

.header-edit h1 {
  color: #172B4D;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header-edit span {
  color: #A8A8A8;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-edit {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
  grid-gap: 25px;
  gap: 25px;
}

/* Fields Form */

.field-text-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}

.field-text-edit label, .field-select-edit label, .title-radio-edit, .title-checkbox-edit, .field-textarea-edit label, .title-image-edit {
  color: #172B4D;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.field-text-edit span {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
  padding-bottom: 10px;
  margin-top: -5px;
}

.subtitle-image-edit {
  color: #A8A8A8;
  font-family: Roboto Flex;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -35px;
}

.field-text-edit input {
  width: 100%;
  height: 44px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #EBECF0;
  background: #FFF;
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 15px;
}

.field-text-edit input::placeholder, .field-textarea-edit textarea::placeholder {
  color: #A8A8A8;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.field-edit {
  outline: none;
  transition: all 200ms ease-in-out;
}

.field-edit input:focus {
  border-color: #172B4D;
}

.field-select-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}

.field-select-edit select { /* Firefox */
  -webkit-appearance:none !important; /* Safari and Chrome */
  appearance:none !important;
  background-image: url(/static/media/arrow_down_select_cae.976b45ea.svg) !important;
  background-repeat: no-repeat !important;
  background-position-x: 95% !important;
  background-position-y: 50% !important;
  background-size: 13px !important;
  width: 100%;
  height: 44px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #EBECF0;
  background: #FFF;
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 15px;
}

.field-select-edit option {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.field-radio-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}

.field-radio-edit a {
  color: #2280BD;
}

.field-radio-edit span {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
  padding-bottom: 15px;
  margin-top: -8px;
}

.div-align-radio-field {
  min-height: 44px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

.div-radio-edit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.label-radio-edit {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.radio-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 20px;
  width: 20px;
}

.radio-edit input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #172B4D;
  transition: all 200ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50px;
}

.radio-edit input:checked ~ .radio-input {
  background-color: #172B4D;
  border-color: #172B4D;
}

.check-radio-input {
  content: "";
  position: absolute;
  display: none;
  align-self: center;
  justify-self: center;
  width: 5px;
  height: 10px;
  border: 1px solid white;
  border-width: 0 1.5px 1.5px 0;
  transform: rotate(45deg);
  top: 3px;
}

.radio-edit input:checked ~ .radio-input .check-radio-input {
  display: block;
}

.field-checkbox-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.field-checkbox-edit span {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
  padding-bottom: 15px;
  margin-top: -8px;
}

.field-checkbox-edit a {
  color: #2280BD;
}

.div-align-checkbox-field {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px;
}

.div-checkbox-edit {
  width: 160px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.label-checkbox-edit {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.checkbox-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 20px;
  width: 20px;
}

.disabled-checkmark {
  cursor: default;
}

.checkbox-edit input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #172B4D;
  transition: all 200ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
}

.checkbox-edit input:checked ~ .checkbox-input {
  background-color: #172B4D;
  border-color: #172B4D;
}

.check-checkbox-input {
  content: "";
  position: absolute;
  display: none;
  align-self: center;
  justify-self: center;
  width: 5px;
  height: 10px;
  border: 1px solid white;
  border-width: 0 1.5px 1.5px 0;
  transform: rotate(45deg);
  top: 3px;
}

.checkbox-edit input:checked ~ .checkbox-input .check-checkbox-input {
  display: block;
}

.field-image-edit {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 46px;
  gap: 46px;
}

.div-upload-image {
  width: 100%;
  height: 150px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  background-color: #FFFFFF;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23172B4DFF' stroke-width='3' stroke-dasharray='10' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
  cursor: pointer;
}

.div-upload-image input {
  display: none;
}

.div-upload-image img {
  width: 53.895px;
  height: 46.824px;
  flex-shrink: 0;
}

.div-upload-image span {
  color: #D2D2D2;
  font-family: Roboto Flex;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.field-textarea-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}

.field-textarea-edit span {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
  padding-bottom: 15px;
  margin-top: -8px;
}


.field-textarea-edit textarea {
  width: 100%;
  height: 150px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #EBECF0;
  background: #FFF;
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 15px;
  padding-top: 10px;
  resize: none;
}

.slice-section-form {
  width: 100%;
  height: 2px;
  flex-shrink: 0;
  background: #EBECF0;
}

.div-align-buttons-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-gap: 15px;
  gap: 15px;
  width: 100%;
}

.div-align-buttons-form button:first-child {
  width: 130px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 2px solid #D5D9E2;
  background: transparent;
  font-family: Roboto Flex;
  color: #172B4D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.div-align-buttons-form button:last-child {
  width: 130px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 2px solid #172B4D;
  background: #172B4D;
  color: #FFF;
  font-family: Roboto Flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.div-align-buttons-form button:focus, .div-align-buttons-form button:active {
  outline: none;
}

.div-align-images-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  margin-top: -40px;
}

.div-image-edit {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 58px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 2px solid #EBECF0;
  background: #FFF;
}

.preview-image  {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding-left: 20px;
}

.info-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 80px;
  gap: 80px;
  padding-right: 30px;
}

.preview-image span, .info-image span {
  color: #172B4D;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.info-image img {
  cursor: pointer;
}

.size-file {
  margin-right: -50px;
  text-align: right;
  width: 90px;
}

.preview-image img {
  width: 100%;
}

.div-limit-image {
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.div-align-radio-multiple-field {
  min-height: 44px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.row-radio-multiple {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  height: 40px;
}

.subrow-radio-multiple {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  height: 45px;
  background-color: #FFF;
  border-radius: 5px;
  border: 1px solid #EBECF0;
}

.column-radio-multiple {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #172B4D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subcolumn-radio-multiple {
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #172B4D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subrow-radio-multiple .subcolumn-radio-multiple .radio-edit {
  margin-top: 8px;
}

.field-image-edit .error-message-form-cae {
  text-align: left;
}

.other-option-input-edit {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #172B4D;
  outline: none;
  margin-right: 10px;
  margin-top: -10px;
  width: 70%;
  justify-self: flex-start;
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#handle-set-doc-button:disabled {
  background: #111C30;
  cursor: default;
}
.PopupConfirmExperience {
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.32);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .inner-popup-confirm-experience {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    grid-gap: 10px;
    gap: 10px;
    width: 500px;
    height: 220px;
    overflow: hidden;
  }
  
  .div-align-info-popup-confirm-experience {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
    height: 60px;
    background-color: #172B4D;
    padding-left: 25px;
  }
  
  .div-align-info-popup-confirm-experience span:first-child {
    color: white;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
  }
  
  .div-align-button-confirm-experience {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-gap: 10px;
    gap: 10px;
    margin-right: 30px;
    margin-bottom: 15px;
  }
  
  .div-align-button-confirm-experience button {
    width: 120px;
    height: 40px;
    border-radius: 10px;
    background: #172B4D;
    color: #FFF;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: none;
    outline: none;
  }
  
  .div-align-button-confirm-experience button:active, .div-align-button-confirm-experience button:focus {
    outline: none;
  }
  
  .div-align-button-delete-experience {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
  }
  
  .div-align-button-delete-experience button:first-child {
    background-color: white;
    color: #172B4D;
    border: 2px solid #172B4D;
    width: 120px;
  }
  
  .div-align-button-delete-experience button:last-child {
    width: 140px;
  }
  
  .div-align-button-confirm-experience button:first-child {
    background-color: white;
    color: #172B4D;
    border: 2px solid rgba(0, 0, 0, 0.205);
    width: 120px;
  }

  .div-align-button-delete-experience button:last-child:disabled {
    background-color: #0d1d38;
    cursor: default;
  }

  .div-align-button-confirm-experience button:first-child:disabled {
    background-color: #b8b8b8;
    cursor: default;
  }
  
  .div-align-button-confirm-experience button:last-child {
    width: 140px;
  }
  
  .div-align-linear-progress-bar {
    width: calc(100% - 50px);
    margin: 0 auto;
    height: 80px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

.info-span-popup-confirm-experience {
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: left;
  color: rgba(0, 0, 0, 0.54);
}

.initial-info-span-popup-confirm-experience {
  text-align: justify;
}
.PopupNewUser {
  position:absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.32);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner-popup-user {
  width: 90%;
  max-width: 360px;
  height: 400px;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-gap: 20px;
  gap: 20px;
}

.div-align-info-popup-newuser {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
}

.div-align-info-popup-newuser span:first-child {
  color: #172B4D;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.div-align-info-popup-newuser span:last-child {
  color: #42526E;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.div-align-button-newuser {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.div-align-button-newuser button {
  width: 150px;
  height: 45px;
  border-radius: 25px;
  background: #172B4D;
  color: #FFF;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border: none;
  outline: none;
}

.div-align-button-newuser button:active, .div-align-button-newuser button:focus {
  outline: none;
}

.div-align-button-delete-experience {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
}

.div-align-button-delete-experience button:first-child {
  background-color: white;
  color: #172B4D;
  border: 2px solid #172B4D;
  width: 120px;
}

.div-align-button-delete-experience button:last-child {
  width: 140px;
}
.ProgressBar {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.div-align-progressbar {
  width: 100%;
  border-radius: 0.375rem;
  background-color: #F6F7FC;
  height: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0 auto;
}

.div-progress-bar {
  background-size: 1rem 1rem;
  height: 100%;
}

.red-progress-bar {
  background-color: rgba(239, 69, 69, 0.59);
  background-image: linear-gradient(45deg,#ffffff26 25%,#0000 0,#0000 50%,#ffffff26 0,#ffffff26 75%,#0000 0,#0000);
  background-size: 1rem 1rem;
}

.yellow-progress-bar {
  background: rgba(251, 255, 0, 0.747);
  background-image: linear-gradient(45deg,#ffffff26 25%,#0000 0,#0000 50%,#ffffff26 0,#ffffff26 75%,#0000 0,#0000);
  background-size: 1rem 1rem;
}

.green-progress-bar {
  background: rgba(42, 202, 77, 0.62);
  background-image: linear-gradient(45deg,#ffffff26 25%,#0000 0,#0000 50%,#ffffff26 0,#ffffff26 75%,#0000 0,#0000);
  background-size: 1rem 1rem;
}

.div-progress-pointer {
  width: 50px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -25px;
  z-index: 10;
  border-radius: 0.375rem;
  background-color: #172b4d;
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.div-progress-pointer span {
  color: white;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div-progress-pointer-base {
  width: 10px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  z-index: 10;
  background-color: transparent;
  border-top: 5px solid #172b4d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.ProgressBar:hover .div-progress-pointer, .ProgressBar:hover .div-progress-pointer-base {
  opacity: 1;
}
.Cae {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
}

.header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.22);
  background: #FFF;
}

.logo-div-header, .options-header {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.logo-div-header:first-child {
  padding-left: 15px;
}

.logo-div-header span {
  color: #000;
  font-family: 'Jaldi', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo-div-header img {
  width: 100px;
}

.options-header {
  position: relative;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;
  margin-right: 15px;
  border-radius: 50px;
  cursor: pointer;
}

.options-header:hover {
  background: #EBECF0;
}

.img-options-header {
  cursor: pointer;
}

.popup-options-header span {
  padding-left: 5%;
  color: #172B4D;
  font-family: Jaldi;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.popup-options-header {
  position: absolute;
  right: 0;
  top: 48px;
  width: 312px;
  height: 120px;
  flex-shrink: 0;
  background: #EBECF0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: none;
}

.hr-div {
  background: #D9D9D9;
  width: 90%;
  margin: 0 auto;
  height: 1px;
}

.theme-options {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 6px;
  gap: 6px;
  height: 130px;
}

.div-theme {
  width: 140px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
}

.theme-options #active-theme {
  border: 2px solid #172B4D;
}

.theme-options-card {
  margin-top: 10px;
}

.radio-theme {
  border: 2px solid #172B4D;
}

.label-theme-options {
  color: #172B4D;
  font-family: Jaldi;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div-buttons-options-header {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 15px;
  gap: 15px;
}

.div-buttons-options-header div {
  height: 45px;
  flex-shrink: 0;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
}

.div-buttons-options-header div:first-child {
  width: 144px;
  background: #FFF;
}

.div-buttons-options-header div img {
 padding: 0;
 height: 28px;
 width: 28px;
}

.div-buttons-options-header div:last-child {
  width: 119px;
  background: #172B4D;
}

.div-buttons-options-header div:first-child span {
  color: #172B4D;
  font-family: Jaldi;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div-buttons-options-header div:last-child span {
  color: #EBECF0;
  font-family: Jaldi;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.body-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(100vh - 72px);
}

.navbar-cae {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 267px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.22);
  border-right: 1px solid rgba(0, 0, 0, 0.22);
  background: #FFF;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.handler-pages {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: #F6F7FC;
  overflow-y: scroll;
}

.handler-pages::-webkit-scrollbar {
  width: 5px;
  background: #FFF;
}

.handler-pages::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #172B4D;
  border-radius: 5px;
}


.user-profile-navbar {
  width: 88%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  position: relative;
  cursor: pointer;
  transition: 200ms all ease-in-out;
  margin-top: 34.5px;
  margin-bottom: 34.5px;
}

.user-profile-navbar:hover {
  background-color: #f5f5f5;
}

.user-profile-navbar .photo-user-navbar {
  width: 57px;
  height: 57px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #EBECF0;
}

.photo-user-navbar {
  overflow: hidden;
}

.photo-user-navbar img {
  width: 100%;
}

.user-profile-navbar .info-user-navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  grid-gap: 1px;
  gap: 1px;
  transition: all 1000ms ease-in-out;
}

.user-profile-navbar .info-user-navbar span:first-child {
  color: #000;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.user-profile-navbar .info-user-navbar span:last-child {
  color: #42526E;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.handler-size-navbar {
  position: absolute;
  top: 49px;
  right: -30px;
  border-radius: 5px;
  background: #172B4D;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.handler-size-navbar img {
  transition: all 300ms ease-in-out;
}

.items-navbar {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100% - 180px);
  grid-gap: 2px;
  gap: 2px;
  width: 88%;
}

.navbar-item {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  grid-gap: 0;
  gap: 0;
  height: 54px;
  flex-shrink: 0;
  border-radius: 5px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.items-navbar #active {
  background: #EBECF0;
}

.navbar-item:hover {
  background: #EBECF0;
}

.navbar-item:hover span {
  color: #2280BD;
}

.navbar-item:hover img {
  filter: invert(53%) sepia(52%) saturate(710%) hue-rotate(160deg) brightness(75%) contrast(95%);
}

.items-navbar #active span {
  color: #2280BD;
}

.items-navbar #active img {
  filter: invert(53%) sepia(52%) saturate(710%) hue-rotate(160deg) brightness(75%) contrast(95%);
}

.navbar-item span {
  color: #42526E;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.info-text {
  width: calc(100% - 78px);
}

.footer-navbar {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  grid-gap: 5px;
  gap: 5px;
}

.footer-navbar span {
  color: #42526E;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.footer-navbar img {
  width: 80px;
}

@media only screen and (max-width: 1050px) {
  .navbar-cae {
    display: none;
  }

  .table-remove-responsive {
    display: none;
  }
}


@media only screen and (max-width: 600px) {
  .div-create-experiences {
    flex-direction: column;
    justify-content: center;
  }

  .div-search-experiences {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .section-medium-list {
    width: 100%;
  }

  .Experiences {
    padding: 15px;
  }

  .logo-div-header span {
    display: none;
  }
}


@media only screen and (max-width: 590px) {
  .row-radio-multiple, .subrow-radio-multiple {
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .preview-image span {
    width: 50%;
    overflow: hidden;
  }
  
  .info-image span {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .table-remove-responsive-phone {
    display: none;
  }

  .section-medium-list {
    text-align: left;
    width: calc(100% - 55px);
  }

  .div-row-body-list, .div-row-header-list {
    justify-content: space-evenly;
  }
}
.Footer {
  width: 100%;
  height: 66px;
  flex-shrink: 0;
  background: #172B4D;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Footer div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 25px;
}

.Footer div:first-child span {
  margin: 0;
  padding: 0;
  color: #FFF;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.Footer div:last-child {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  grid-gap: 26px;
  gap: 26px;
}

.icon-footer {
  filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(283deg) brightness(7500%) contrast(101%);
  width: 20px;
  height: 20px;
}
.ProfileCae {
    background-color: #F6F7FC;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 45px;
}

.container-profile-cae {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 35px;
    gap: 35px;
}

.headear-profile-cae {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 5px;
    gap: 5px;
}

.headear-profile-cae h1 {
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
}

.headear-profile-cae span {
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    line-height: normal;
    font-weight: 300;
    color: #7a7a7a;
}

.body-profile-cae {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 40px;
    gap: 40px;
}

.body-profile-cae h3 {
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
}

.div-image-profile {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    background-color: #EBECF0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    overflow: hidden;
}

.div-align-image-profile-cae {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.div-align-image-profile-cae img {
    object-fit: cover;
}

.div-aling-button-image-profile-cae {
    width: 140px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.div-aling-button-image-profile-cae button {
    width: 100%;
    height: 43px;
    border-radius: 5px;
    border: 1px solid #EBECF0;
    background: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    font-family: Inter;
    font-size: 15px;
    cursor: pointer;
}

.button-edit-type-profile-cae {
    background-color: #172B4D !important;
    color: white !important;
}

.button-edit-type-profile-cae:hover {
    background-color: #071327 !important;
}

.button-edit-type-profile-cae img {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(68deg) brightness(108%) contrast(101%);
}

.div-aling-button-image-profile-cae button:last-child:hover {
    background-color: #c9c9c9;
}

.div-aling-button-image-profile-cae button:last-child img {
    filter: invert(61%) sepia(80%) saturate(1274%) hue-rotate(330deg) brightness(80%) contrast(117%);
    width: 15px;
}

.form-edit-register {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    grid-gap: 25px;
    gap: 25px;
}
  
.form-edit-register label {
    color: black !important;
    font-size: 14px !important;
}
  
.form-edit-register input {
    color: black !important;
    font-size: 14px !important;
}

.div-align-edit-info-profile-cae {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.div-align-edit-info-profile-cae button {
    width: 200px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #EBECF0;
    background: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    font-family: Inter;
    font-size: 15px;
    cursor: pointer;
}

.div-align-buttons-profile-cae {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
    grid-gap: 15px;
    gap: 15px;
}

.div-align-buttons-profile-cae button {
    width: 150px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #EBECF0;
    background: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    font-family: Inter;
    font-size: 15px;
    cursor: pointer;
}

.div-align-buttons-profile-cae button:last-child {
    background-color: #172B4D;
    color: white;
}

.div-align-buttons-profile-cae button:last-child:hover {
    background-color: #071327 !important;
}

.div-align-buttons-profile-cae button:first-child:hover {
    background-color: #c9c9c9;
}

.error-message-profile-cae {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 30px;
    color: #DA3F21 !important;
    font-size: 13px !important;
    width: 100%;
    text-align: center;
}

.input-profile-cae:disabled {
    color: #8d98a9 !important;
    background-color: #f7f7f7 !important;
}

.input-profile-cae:disabled::placeholder {
    color: #8d98a9 !important;
}
.ResizeImage {
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.32);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inner-popup-resize-image {
    border-radius: 15px;
    background: #19212e;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    grid-gap: 10px;
    gap: 10px;
    width: 400px;
    height: 480px;
    overflow: hidden;
    padding: 25px;
}

.div-align-input-resize-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.div-align-input-resize-image input {
    color: black;
    width: 80%;
    border-radius: 10px;
}

.div-align-input-resize-image input::-webkit-slider-thumb {
    background-color: #172B4D;
    border: 2px solid white;
}

  
.div-align-input-resize-image input::-moz-range-thumb {
    background-color: #172B4D;
    border: 2px solid white;
}

.div-align-buttons-resize-image {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    height: 50px;
}

.div-align-buttons-resize-image button {
    width: 120px;
    height: 40px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
    outline-offset: 3px;
}

.div-align-buttons-resize-image button:first-child {
    outline: white solid 1px;
}

.div-align-buttons-resize-image button:last-child {
    outline: white solid 4px;
    border: 1px solid #172B4D;
    background-color: white;
    color: #172B4D;
    outline-offset: 0;
}

.div-align-buttons-resize-image button:last-child:hover {
    background-color: #DDDDDD;
}

.div-align-buttons-resize-image button:first-child:hover {
    background-color: #19212e33;
}
/* Columns */

.row_table_inventory_parent {
    height: 20vh;
    overflow: hidden;
    transition: height ease 0.3s;
    padding-inline: 134.5%;
    padding-bottom: 20vh;
}

.row_table_inventory tr {
    text-align: center;
}

.row_table_inventory_parent_show {
    height: auto;
    padding-inline: 300%;
}

.body_table {
    background-color: var(--firstcolor);
}

.title_table_inventory {
    width: 10%;
    padding: 7px;
}

.desc_table_inventory {
    width: 34%;
    padding: 7px;
}

.column_min_table_inventory {
    width: 5%;
    height: 100%;
}

/* Div's buttons */
.div_mod_inventory {
    height: 100%;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Buttons */
.btn_mod_adm {
    cursor: pointer;
    border: none;
    background: transparent;
    color: #8ac0ec;
    margin: 5px;
}

.btn_mod_adm:disabled{
  color: #666666;
  cursor: default;
}

.btn_mod_adm:disabled:hover{
    color: #666666;
  }

.btn_mod_adm:hover {
    color: #4a81ad;
}
h1, h2, h3, h4, p {
    /* font-family: 'Raleway', sans-serif; */
}

.ListItinerary {
    min-height: 80vh;
    height:100%;
    width:100%;
}
.container {
    display: flex;
    flex-direction: column;
    height:100%;
    width:100%;
}

.title-wrapper {
    margin-bottom: 5%;
}

.pretitle {
    font-size: 2.3vw;
    font-weight: 300;
    color:rgb(113, 113, 113);
}

.title {
    font-size: 4.6vw;
    font-weight: 800;
}

.subtitle {
    font-size: 1.7vw;
    font-weight: 300;
}

/* Columns */
.row_table_itinerary{
    height: 26.625vh;
    overflow: hidden;
    transition: height ease 0.3s;
    background-color: rgb(229, 227, 240);
    text-align:center;
}

.row_table_itinerary div td {
    text-align: center;
}

.row_table_itinerary tr {
    text-align: center;
    height : 300px;
}

.row_table_itinerary_parent_show {
    height: auto;
}

.body_table {
    background-color: var(--firstcolor);
}

.title_table_itinerary {
    width: 15%;
    padding: 10px;
}

.desc_table_itinerary {
    text-align: center;
    width: 34%;
    padding: 7px;
}

.column_min_table_itinerary {
    text-align: center;
    width: 5%;
    height: 100%;
}

/* Div's buttons */
.div_mod_itinerary {
    text-align: center;
    height: 100%;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Buttons */
.btn_mod_adm_itinerary {
    text-align: center;
    cursor: pointer;
    border: none;
    background: transparent;
    color: #8ac0ec;
    margin: 5px;
}

.btn_mod_adm:hover {
    color: #4a81ad;
}
body {
    overflow-x: hidden;
}

.SidebarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 100vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    z-index: 2;
}

.SideBarBody {
    width: 82%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 60px;
    grid-template-areas:
        'header'
        'pages'
    ;
    transition: all 500ms ease-in-out;
    z-index: 2;
}

.div_align_sidebar {
    width: 18%;
    transition: all 500ms ease-in-out;
    background: none;
    z-index: 2;
}

.iconSidebar:active {
    animation: ease-item 0.5s linear;
}

@keyframes ease-item {
    0% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
}

@keyframes ease-item-delay {
    0% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
}

.HeaderAdminPage {
    grid-area: header;
    z-index: 2;
}

.SidebarPages {
    grid-area: pages;
    z-index: 2;
}

.Sidebar{
    background-color: var(--thirdcolor);
    width: 18%;
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    overflow-x: scroll;
    transition: all 500ms ease-in-out;
    z-index: 10;
    scrollbar-color: var(--thirdcolor) var(--thirdcolor);
    scrollbar-width: none;
}

.Sidebar::-webkit-scrollbar {
    width: 5px;
    height: 0;
    background-color: var(--thirdcolor);
}

.Sidebar::-webkit-scrollbar-thumb {
    background-color: var(--thirdcolor);
}

.SidebarList, .SidebarSubList{
    height: auto;
    width: 100%;
    padding: 0;
}

.SidebarSubList {
    transition: all 300ms ease-in-out;
    height: 0;
    overflow: hidden;
}

.SidebarList .rowSidebarItem, .rowSidebarSubItem {
    width: 100%;
    height: 4vw;
    list-style-type: none;
    margin: 0%;
    display: flex;
    flex-direction: row;
    color: var(--sixthcolor);
    justify-content: flex-start;
    align-items: center;
    font-weight: 1000;
    font-size: 1vw;
}

.rowSidebarSubItem {
    height: 6vh;
}

.titleSidebarSubItem {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
}

.SidebarList .rowSidebarItem:hover, .rowSidebarSubItem:hover{
    cursor: pointer;
    background-color: var(--fourthcolor);
    color: var(--firstcolor);
}

.SidebarList #active {
    background-color: var(--fourthcolor);
    color: var(--firstcolor);
}

.rowSidebarItem #icon {
    flex: 30% 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1.15rem !important;
}

.rowSidebarItem #title {
    flex: 70% 1;
}

.rowSidebarSubItem #title {
    padding-left: 2rem;
    flex: 100% 1;
}

.rowSidebarItem #img_arrow {
    flex: 10% 1;
}

.SidebarName {
    color: var(--firstcolor);
    font-size: 1vw;
    transition: all 300ms ease-in-out;
}

.SidebarHeader{
    background: linear-gradient(
    180deg,
    var(--fifthcolor) 40%,
    var(--fourthcolor) 100%
    );
    padding-bottom: 10%;
    padding-left: 10%;
    padding-top: 10%;
    font-size: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.logoHeader {
    width: 90%;
    max-width: 60px;
}

.ImageSidebar{
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.titleSidebar {
    opacity: 1;
    transition: all 250ms ease-in-out;
}

.arrow_down_image {
    width: 35px;
    transform: rotate(-90deg);
    transition: all 300ms ease-in-out;
}
.AppAdmin{
    height: auto;
    width: auto;
}

:root {
    --firstcolor: white;
    --secondcolor: #f3f3f4;
    --thirdcolor: #2F4050;
    --fourthcolor: #293846;
    --fifthcolor: #2B3C4B;
    --sixthcolor: #B7BCC1;
    --seventhcolor: #696C6E;
    --eighthcolor: black;
    --ninethcolor: #18A689;
    --tenth: #f8ac58;
    --eleventhcolor: #e7eaec;
    --twenthcolor: #e8e9ea;
    --thirteenthcolor: #2d8f7b;
    --fourteenthcolor: #0cddb3;
    --fifteenthcolor: #16806a;
}

@font-face {
    font-family: opensans-regular;
    src: url(/static/media/OpenSans-Regular.629a55a7.ttf);
}

@font-face {
    font-family: opensans-bold;
    src: url(/static/media/OpenSans-Bold.50145685.ttf);
}

@font-face {
    font-family: opensans-semibold;
    src: url(/static/media/OpenSans-Semibold.33f225b8.ttf);
}

@font-face {
    font-family: opensans-light;
    src: url(/static/media/OpenSans-Light.1bf71be1.ttf);
}
.CaeRegister {
  background: #EBF1FF;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  padding-top: 107px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main-container-cae-register {
  width: 957.5px;
  min-height: 538px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 28px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  overflow: hidden;
  background-color: white;
  grid-gap: 38px;
  gap: 38px;
  padding-top: 48px;
  padding-bottom: 20px;
}

.header-container-cae-register { 
  width: 62.5%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  grid-gap: 2px;
  gap: 2px;
}

.header-container-cae-register span:first-child {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header-container-cae-register span:last-child {
  color: #42526E;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.div-title-cae-register { 
  width: 62.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.div-title-cae-register h1 {
  color: #172B4D;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
}

.div-title-cae-register h6 {
  font-family: Inter;
  color: #42526E;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}

.div-body-cae-register {
  width: 62.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  grid-gap: 3px;
  gap: 3px;
  padding-top: 15px;
}

.div-body-cae-register p {
  color: #42526E;
  font-family: Inter;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: justify;
}

.div-body-cae-register ul {
  stroke-width: 5px;
  color: #42526E;
  font-family: Inter;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  list-style-type: circle;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 18px;
  gap: 18px;
  padding-left: 32px;
}

.line-break-cae-register {
  background: #EBECF0;
  height: 2px;
  width: 100%;
  margin: 0 auto;
  margin-top: 14px;
}

.div-terms-accepted {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  padding-left: 16px;
  padding-top: 30px;
}

.div-radio-cae {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  padding-left: 16px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.label-terms-text {
  color: #42526E;
  font-family: Inter;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}


/* Checkbox css */
.label-terms-accepted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 13px;
  width: 13px;
}

.label-terms-accepted input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark-terms {
  position: absolute;
  top: 0;
  left: 0;
  height: 13px;
  width: 13px;
  background-color: #eee;
  border: 1px solid #eee;
  transition: all 100ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
}

.checkmark-radio-cae {
  border-radius: 50px;
}

.label-terms-accepted:hover input ~ .checkmark-terms {
  background-color: #ccc;
  border-color: #172B4D;
}

.label-terms-accepted input:checked ~ .checkmark-terms {
  background-color: #172B4D;
  border-color: #172B4D;
}

.checkmark-terms:after {
  content: "";
  position: absolute;
  display: none;
}

.label-terms-accepted input:checked ~ .checkmark-terms:after {
  display: block;
}

.label-terms-accepted .checkmark-terms:after {
  align-self: center;
  justify-self: center;
  width: 3px;
  height: 7px;
  border: 1px solid white;
  border-width: 0 1.5px 1.5px 0;
  transform: rotate(45deg);
  margin-top: -1px;
}

.div-buttons-cae-register {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 82px;
  grid-gap: 20px;
  gap: 20px;
}

.div-buttons-cae-register button {
  width: 120px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #172B4D;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  color: #FFF;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  transition: all 200ms ease-in-out;
} 

.div-buttons-cae-register button:last-child:hover {
  background: #111C30;
}

.div-buttons-cae-register button:last-child:disabled {
  background: #111C30;
  cursor: default;
}

.div-buttons-cae-register button:first-child:hover {
  background: #EBECF0;
}


.div-buttons-cae-register button:active, .div-buttons-cae-register button:focus {
  outline: none;
}

.div-buttons-cae-register button:first-child {
  border: 1px solid #EBECF0;
  background: #FFF;
  color: #A3A3A3;
}

.div-align-radio-cae-register {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 15px;
  gap: 15px;
}

.div-form-cae-register { 
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.div-form-cae-register span {
  color: #172B4D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 30px;
}

.error-message-form-cae {
  color: #DA3F21 !important;
  font-size: 13px !important;
  width: 100%;
  margin-top: -20px;
}

.second-error-message-form-cae {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 30px;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .CaeRegister {
    padding-top: 15px;
  }

  .main-container-cae-register {
    width: 95%;
  }

  .container-cae-login:first-child {
    width: 100%;
  }

  .container-cae-login:last-child {
    display: none;
  }
  
  .header-container-cae-register, .div-title-cae-register, .div-body-cae-register {
    width: 80%;
  }
}

@media only screen and (max-width: 575px) {
  .CaeRegister {
    padding-top: 0;
  }

  .main-container-cae-register {
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
    border-radius: 0;
    padding-top: 25px;
    
  }

  .container-cae-login:first-child {
    width: 100%;
    height: 100%;
  }

  .main-container-cae-login {
    border-radius: 0;
  }

  .container-cae-login:last-child {
    display: none;
  }
  
  .header-container-cae-register, .div-title-cae-register, .div-body-cae-register {
    width: 90%;
  }

  .FieldFormCaeExplanation span{
    margin-top: 10px !important;
  }
}
.FieldFormCae {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FieldFormCaeExplanation {
  min-height: 110px;
  justify-content: space-between;
}

.FieldFormCae input, .FieldFormCae select, .FieldFormCae textarea {
  border-radius: 5px;
  border: 1px solid #EBECF0;
  background: #EBECF0;
  height: 35px;
  width: 100%;
  color: rgba(23, 43, 77, 0.62);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 20px;
  outline: none;
}

.FieldFormCae span {
  color: #42526E;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: -25px;
}

.FieldFormCae textarea {
  height: 140px;
  padding-top: 15px;
}

.FieldFormCae select { /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background-image: url(/static/media/arrow_down_select_cae.976b45ea.svg);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  background-size: 10px;
}

.FieldFormCae select::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: rgba(23, 43, 77, 0.62);
}

.FieldFormCae select::-webkit-scrollbar {
  width: 3px;
}

.FieldFormCae input::placeholder, .FieldFormCae textarea::placeholder {
  color: rgba(23, 43, 77, 0.62);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.FieldFormCae label {
  color: #172B4D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.div-radio-cae-form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  padding: 0;
  margin: 0;
  grid-gap: 20px;
  gap: 20px;
}

.div-align-radio-cae-form {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  height: 100%;
}

.radio-form-cae {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 18px;
  width: 18px;
}

.radio-form-cae input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check-icon-radio-form-cae {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border: 1px solid #eee;
  transition: all 100ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50px;
}

.radio-form-cae:hover input ~ .check-icon-radio-form-cae {
  background-color: #ccc;
  border-color: #172B4D;
}

.radio-form-cae input:checked ~ .check-icon-radio-form-cae {
  background-color: #172B4D;
  border-color: #172B4D;
}

.check-icon-radio-form-cae:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-form-cae input:checked ~ .check-icon-radio-form-cae:after {
  display: block;
}

.radio-form-cae .check-icon-radio-form-cae:after {
  align-self: center;
  justify-self: center;
  width: 3px;
  height: 7px;
  border: 1px solid white;
  border-width: 0 1.5px 1.5px 0;
  transform: rotate(45deg);
  margin-top: -1px;
}

.label-radio-form-cae {
  color: #42526E;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-align: justify;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

