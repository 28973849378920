.CardChartDashboard {
    background-color: white;
    width: 50%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8px;
    border-top: 5px solid #e7eaec;
}

.div_header_chart_dashboard {
    height: 80px;
}

.div_align_chart_dashboard {
    width: 100%;
    height: calc(100% - 80px);
    display: flex;
}

.sunburst-viz {
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sunburst-viz svg {
    width: 150% !important;
}

.sunburst-viz g {
    font-size: 20px !important;
}

.div_popup_chart {
    position: absolute;
    background-color: red;
    z-index: 1000;
}

.tooltip {
    opacity: 1 !important;
}