.Loading {
    width: 82.8%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f3f3f4;
    overflow: hidden;
    z-index: 1000;
}

.loader {
    margin: auto;
    border: 15px solid #EAF0F6;
    border-radius: 50%;
    border-top: 15px solid #18A689;
    width: 150px;
    height: 150px;
    animation: spinner 4s linear infinite;
    margin: auto;
  }
  
@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}