.ProfileCae {
    background-color: #F6F7FC;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 45px;
}

.container-profile-cae {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 35px;
}

.headear-profile-cae {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
}

.headear-profile-cae h1 {
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
}

.headear-profile-cae span {
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    line-height: normal;
    font-weight: 300;
    color: #7a7a7a;
}

.body-profile-cae {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
}

.body-profile-cae h3 {
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
}

.div-image-profile {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    background-color: #EBECF0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    overflow: hidden;
}

.div-align-image-profile-cae {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    align-items: center;
    height: fit-content;
}

.div-align-image-profile-cae img {
    object-fit: cover;
}

.div-aling-button-image-profile-cae {
    width: 140px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.div-aling-button-image-profile-cae button {
    width: 100%;
    height: 43px;
    border-radius: 5px;
    border: 1px solid #EBECF0;
    background: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-family: Inter;
    font-size: 15px;
    cursor: pointer;
}

.button-edit-type-profile-cae {
    background-color: #172B4D !important;
    color: white !important;
}

.button-edit-type-profile-cae:hover {
    background-color: #071327 !important;
}

.button-edit-type-profile-cae img {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(68deg) brightness(108%) contrast(101%);
}

.div-aling-button-image-profile-cae button:last-child:hover {
    background-color: #c9c9c9;
}

.div-aling-button-image-profile-cae button:last-child img {
    filter: invert(61%) sepia(80%) saturate(1274%) hue-rotate(330deg) brightness(80%) contrast(117%);
    width: 15px;
}

.form-edit-register {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    gap: 25px;
}
  
.form-edit-register label {
    color: black !important;
    font-size: 14px !important;
}
  
.form-edit-register input {
    color: black !important;
    font-size: 14px !important;
}

.div-align-edit-info-profile-cae {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.div-align-edit-info-profile-cae button {
    width: 200px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #EBECF0;
    background: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-family: Inter;
    font-size: 15px;
    cursor: pointer;
}

.div-align-buttons-profile-cae {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
    gap: 15px;
}

.div-align-buttons-profile-cae button {
    width: 150px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #EBECF0;
    background: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-family: Inter;
    font-size: 15px;
    cursor: pointer;
}

.div-align-buttons-profile-cae button:last-child {
    background-color: #172B4D;
    color: white;
}

.div-align-buttons-profile-cae button:last-child:hover {
    background-color: #071327 !important;
}

.div-align-buttons-profile-cae button:first-child:hover {
    background-color: #c9c9c9;
}

.error-message-profile-cae {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 30px;
    color: #DA3F21 !important;
    font-size: 13px !important;
    width: 100%;
    text-align: center;
}

.input-profile-cae:disabled {
    color: #8d98a9 !important;
    background-color: #f7f7f7 !important;
}

.input-profile-cae:disabled::placeholder {
    color: #8d98a9 !important;
}