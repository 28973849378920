.ResizeImage {
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.32);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inner-popup-resize-image {
    border-radius: 15px;
    background: #19212e;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 10px;
    width: 400px;
    height: 480px;
    overflow: hidden;
    padding: 25px;
}

.div-align-input-resize-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.div-align-input-resize-image input {
    color: black;
    width: 80%;
    border-radius: 10px;
}

.div-align-input-resize-image input::-webkit-slider-thumb {
    background-color: #172B4D;
    border: 2px solid white;
}

  
.div-align-input-resize-image input::-moz-range-thumb {
    background-color: #172B4D;
    border: 2px solid white;
}

.div-align-buttons-resize-image {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: 50px;
}

.div-align-buttons-resize-image button {
    width: 120px;
    height: 40px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
    outline-offset: 3px;
}

.div-align-buttons-resize-image button:first-child {
    outline: white solid 1px;
}

.div-align-buttons-resize-image button:last-child {
    outline: white solid 4px;
    border: 1px solid #172B4D;
    background-color: white;
    color: #172B4D;
    outline-offset: 0;
}

.div-align-buttons-resize-image button:last-child:hover {
    background-color: #DDDDDD;
}

.div-align-buttons-resize-image button:first-child:hover {
    background-color: #19212e33;
}