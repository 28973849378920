@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300;8..144,400;8..144,500&display=swap');
.edit-container {
  max-width: 1183px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  gap: 20px;
}

.div-path-edit {
  color: #42526E;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header-edit {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.header-edit h1 {
  color: #172B4D;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header-edit span {
  color: #A8A8A8;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-edit {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
  gap: 25px;
}

/* Fields Form */

.field-text-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.field-text-edit label, .field-select-edit label, .title-radio-edit, .title-checkbox-edit, .field-textarea-edit label, .title-image-edit {
  color: #172B4D;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.field-text-edit span {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
  padding-bottom: 10px;
  margin-top: -5px;
}

.subtitle-image-edit {
  color: #A8A8A8;
  font-family: Roboto Flex;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -35px;
}

.field-text-edit input {
  width: 100%;
  height: 44px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #EBECF0;
  background: #FFF;
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 15px;
}

.field-text-edit input::placeholder, .field-textarea-edit textarea::placeholder {
  color: #A8A8A8;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.field-edit {
  outline: none;
  transition: all 200ms ease-in-out;
}

.field-edit input:focus {
  border-color: #172B4D;
}

.field-select-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.field-select-edit select {
  -moz-appearance:none !important; /* Firefox */
  -webkit-appearance:none !important; /* Safari and Chrome */
  appearance:none !important;
  background-image: url(../../imgs/arrow_down_select_cae.svg) !important;
  background-repeat: no-repeat !important;
  background-position-x: 95% !important;
  background-position-y: 50% !important;
  background-size: 13px !important;
  width: 100%;
  height: 44px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #EBECF0;
  background: #FFF;
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 15px;
}

.field-select-edit option {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.field-radio-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.field-radio-edit a {
  color: #2280BD;
}

.field-radio-edit span {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
  padding-bottom: 15px;
  margin-top: -8px;
}

.div-align-radio-field {
  min-height: 44px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}

.div-radio-edit {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.label-radio-edit {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.radio-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 20px;
  width: 20px;
}

.radio-edit input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #172B4D;
  transition: all 200ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50px;
}

.radio-edit input:checked ~ .radio-input {
  background-color: #172B4D;
  border-color: #172B4D;
}

.check-radio-input {
  content: "";
  position: absolute;
  display: none;
  align-self: center;
  justify-self: center;
  width: 5px;
  height: 10px;
  border: 1px solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 3px;
}

.radio-edit input:checked ~ .radio-input .check-radio-input {
  display: block;
}

.field-checkbox-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.field-checkbox-edit span {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
  padding-bottom: 15px;
  margin-top: -8px;
}

.field-checkbox-edit a {
  color: #2280BD;
}

.div-align-checkbox-field {
  height: fit-content;
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.div-checkbox-edit {
  width: 160px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.label-checkbox-edit {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.checkbox-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 20px;
  width: 20px;
}

.disabled-checkmark {
  cursor: default;
}

.checkbox-edit input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #172B4D;
  transition: all 200ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
}

.checkbox-edit input:checked ~ .checkbox-input {
  background-color: #172B4D;
  border-color: #172B4D;
}

.check-checkbox-input {
  content: "";
  position: absolute;
  display: none;
  align-self: center;
  justify-self: center;
  width: 5px;
  height: 10px;
  border: 1px solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 3px;
}

.checkbox-edit input:checked ~ .checkbox-input .check-checkbox-input {
  display: block;
}

.field-image-edit {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 46px;
}

.div-upload-image {
  width: 100%;
  height: 150px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #FFFFFF;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23172B4DFF' stroke-width='3' stroke-dasharray='10' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
  cursor: pointer;
}

.div-upload-image input {
  display: none;
}

.div-upload-image img {
  width: 53.895px;
  height: 46.824px;
  flex-shrink: 0;
}

.div-upload-image span {
  color: #D2D2D2;
  font-family: Roboto Flex;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.field-textarea-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.field-textarea-edit span {
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
  padding-bottom: 15px;
  margin-top: -8px;
}


.field-textarea-edit textarea {
  width: 100%;
  height: 150px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #EBECF0;
  background: #FFF;
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 15px;
  padding-top: 10px;
  resize: none;
}

.slice-section-form {
  width: 100%;
  height: 2px;
  flex-shrink: 0;
  background: #EBECF0;
}

.div-align-buttons-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
}

.div-align-buttons-form button:first-child {
  width: 130px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 2px solid #D5D9E2;
  background: transparent;
  font-family: Roboto Flex;
  color: #172B4D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.div-align-buttons-form button:last-child {
  width: 130px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 2px solid #172B4D;
  background: #172B4D;
  color: #FFF;
  font-family: Roboto Flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.div-align-buttons-form button:focus, .div-align-buttons-form button:active {
  outline: none;
}

.div-align-images-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  margin-top: -40px;
}

.div-image-edit {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 58px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 2px solid #EBECF0;
  background: #FFF;
}

.preview-image  {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
}

.info-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  padding-right: 30px;
}

.preview-image span, .info-image span {
  color: #172B4D;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.info-image img {
  cursor: pointer;
}

.size-file {
  margin-right: -50px;
  text-align: right;
  width: 90px;
}

.preview-image img {
  width: 100%;
}

.div-limit-image {
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.div-align-radio-multiple-field {
  min-height: 44px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.row-radio-multiple {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  height: 40px;
}

.subrow-radio-multiple {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  height: 45px;
  background-color: #FFF;
  border-radius: 5px;
  border: 1px solid #EBECF0;
}

.column-radio-multiple {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #172B4D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subcolumn-radio-multiple {
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #172B4D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subrow-radio-multiple .subcolumn-radio-multiple .radio-edit {
  margin-top: 8px;
}

.field-image-edit .error-message-form-cae {
  text-align: left;
}

.other-option-input-edit {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #172B4D;
  outline: none;
  margin-right: 10px;
  margin-top: -10px;
  width: 70%;
  justify-self: flex-start;
  color: #172B4D;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#handle-set-doc-button:disabled {
  background: #111C30;
  cursor: default;
}